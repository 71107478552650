import { FC, useState } from "react";

import { IconSprite } from "../iconSprite";

import { useAppSelector } from "hooks/state";

export interface FilterProps {
    title?: string,
    handleFilter?: Function,
    activeAuthor?: string,
    activeTime?: string,
    activeGenres?: string,
    activeСollection?: string,
    lenBook?: number
};

export const Filter: FC<FilterProps> = ({
    title = 'Мої Аудіокниги',
    handleFilter = () => { },
    activeAuthor = '',
    activeTime = '',
    activeGenres = '',
    activeСollection = '',
    lenBook = 0,
}) => {
    const [idOpenMobFilter, setIdOpenMobFilter] = useState<number>(0);
    const { theme, filters } = useAppSelector(state => state);

    const handleOpenMob = (id: number) => {
        if (theme.phone) {
            setIdOpenMobFilter(id);
        }
    }

    const handleCloseMob = () => setIdOpenMobFilter(0);

    const handleFil = ({ key, name }: { key: string, name: string }) => {
        handleFilter({ key, name });
        handleCloseMob();
    }

    return (
        <div className="filter">
            <div className="container">
                <div className="filter__row">
                    <div className="filter__title">{title}</div>
                    <div className="fm-wr">
                        <div className="fm">
                            <div className={`fm-item fm-item_one type_one ${(activeAuthor.length > 0) ? 'active' : ''} ${(filters.author.length == 0 || lenBook === 0) ? "non" : ""} ${(idOpenMobFilter == 1) ? "open" : ""}`}>
                                <div className="fm-item_btn " onClick={() => (idOpenMobFilter == 1) ? handleCloseMob() : handleOpenMob(1)}>
                                    <span>За автором</span>
                                    <i><IconSprite id={theme.phone ? "mitemfm_type_one" : "type_border_btn_one"} /></i>
                                </div>
                                <div className="fm-item_m">
                                    <div className="fm-item_m_d">
                                        <ul>
                                            {
                                                filters.author.map((item, index) => (
                                                    <li key={index}>
                                                        <button onClick={() => handleFil({ key: 'author', name: item })} className={`${(item === activeAuthor) ? 'active' : ''}`}>{item}</button>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={`fm-item fm-item_two type_two ${(activeTime.length > 0) ? 'active' : ''} ${(lenBook === 0) ? "non" : ""} ${(idOpenMobFilter == 2) ? "open" : ""}`}>
                                <div className="fm-item_btn" onClick={() => (idOpenMobFilter == 2) ? handleCloseMob() : handleOpenMob(2)}>
                                    <span>За тривалістю</span>
                                    <i><IconSprite id={theme.phone ? "mitemfm_type_three" : "type_border_btn_two"} /></i>
                                </div>
                                <div className="fm-item_m">
                                    <div className="fm-item_m_d">
                                        <ul>
                                            <li>
                                                <button onClick={() => handleFil({ key: 'size', name: 'asc' })} className={`${('asc' === activeTime) ? 'active' : ''}`}>Від коротших до довших</button>
                                            </li>
                                            <li>
                                                <button onClick={() => handleFil({ key: 'size', name: 'desc' })} className={`${('desc' === activeTime) ? 'active' : ''}`}>Від довших до коротниш</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={`fm-item fm-item_three type_three ${(activeGenres.length > 0) ? 'active' : ''} ${(filters.genres.length == 0 || lenBook === 0) ? "non" : ""} ${(idOpenMobFilter == 3) ? "open" : ""}`}>
                                <div className="fm-item_btn" onClick={() => (idOpenMobFilter == 3) ? handleCloseMob() : handleOpenMob(3)}>
                                    <span>За жанром</span>
                                    <i><IconSprite id={theme.phone ? "mitemfm_type_two" : "type_border_btn_three"} /></i>
                                </div>
                                <div className="fm-item_m">
                                    <div className="fm-item_m_d">
                                        <ul>
                                            {
                                                filters.genres.map((item, index) => (
                                                    <li key={index}>
                                                        <button onClick={() => handleFil({ key: 'genres', name: item })} className={`${(item === activeGenres) ? 'active' : ''}`}>{item}</button>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={`fm-item fm-item_four type_one ${(activeСollection.length > 0) ? 'active' : ''} ${(idOpenMobFilter == 4) ? "open" : ""} ${(filters.collection.length == 0 || lenBook === 0) ? "non" : ""}`}>
                                <div className="fm-item_btn " onClick={() => (idOpenMobFilter == 4) ? handleCloseMob() : handleOpenMob(4)}>
                                    <span>Колекції</span>
                                    <i><IconSprite id={theme.phone ? "mitemfm_type_one" : "type_border_btn_one"} /></i>
                                </div>
                                <div className="fm-item_m">
                                    <div className="fm-item_m_d">
                                        <ul>
                                            {
                                                filters.collection.map((item, index) => (
                                                    <li key={index}>
                                                        <button onClick={() => handleFil({ key: 'archive_id', name: `${item.id}` })} className={`${(`${item.id}` === activeСollection) ? 'active' : ''}`}>{item.name}</button>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}