import { FC, useState, useEffect } from 'react';

import { Button } from "../button";
import { SelectList } from '../selectList';

import { useAppDispatch, useAppSelector } from 'hooks/state';
import { setCloseModal } from "../../stores/slices/modals";
import { updateSpeed } from "../../stores/slices/player";
import { addNotification } from "../../stores/slices/notifications";

import dataSelect from "../../constants/dataSelects.json";

export interface ModalSpeedProps { };

export const ModalSpeed: FC<ModalSpeedProps> = () => {
    const [stateSelect, setStateSelect] = useState<string>('1');
    const [disabled, setDisabled] = useState<boolean>(true);
    const dispatch = useAppDispatch();
    const { speed } = useAppSelector(state => state.player);

    const handle = (e: string) => {
        setStateSelect(e);
    }

    const send = () => {
        let st = stateSelect === '1';

        dispatch(updateSpeed(stateSelect));
        dispatch(addNotification({
            message: st ? `Швидкість прослуховування звичайна!` : `Швидкість прослуховування збільшено до ${stateSelect}х`,
            icon: 'icon_notif_section'
        }));
        dispatch(setCloseModal());
    }

    useEffect(() => {
        setStateSelect(speed);
    }, []);

    useEffect(() => {
        setDisabled(stateSelect === speed);
    }, [stateSelect]);

    return (
        <div className="modal-content modal-content_386 g-modal-20">
            <div className="modal__title modal__title_icon text_center font_w_500"><span>Оберіть швидкість прослуховування</span></div>
            <SelectList
                list={dataSelect.speed}
                activeKey={stateSelect}
                handle={handle}
            />
            <div className="modal-wr-btn-one">
                <Button label="Слухати" disabled={disabled} handleClick={() => send()} />
            </div>
        </div>
    )
}