import { FC } from "react";

export interface HeadFormProps {
    title?: string,
    desc: string,
    errorCode?: boolean,
    handleRepeatCode?: Function
};

export const HeadForm: FC<HeadFormProps> = ({
    title = 'Вітаємо!',
    desc,
    errorCode = false,
    handleRepeatCode = () => { },
}) => {
    return (
        <>
            <h1 className="aform-h1 font_w_500 text_center">{title}</h1>
            {
                !errorCode && <p className="aform-p aform-p_registr text_center">{desc}</p>
            }
            {
                errorCode && <p className="aform-p aform-p_error aform-p_registr text_center">На жаль, введений код є невірним.</p>
            }
        </>
    )
}