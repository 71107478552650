import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { App } from "./App";

import { store } from "./stores";
import { sessionAuth } from "./stores/controlers/sessions";
import { getAppTerm, getAppInfo, getPolicy } from "./stores/controlers/dataPages";
import { getFaqs } from "./stores/controlers/faqs";

import './assets/styles/index.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

store.dispatch(sessionAuth());
store.dispatch(getAppTerm());
store.dispatch(getAppInfo());
store.dispatch(getPolicy());
store.dispatch(getFaqs());

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
