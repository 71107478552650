import { FC, useRef, useState, MouseEventHandler, useEffect } from "react";
import ReactPlayer from "react-player";

import { IconSprite } from "../iconSprite";
import { Propgress } from "./progress";
import { Volume } from "./volume";

import { formatTime } from "../../units/timePlayer";
import { seconByInteres } from "../../units/players";

import { AutomaticShutdown } from "../../stores/slices/player";

export interface PlayerBookProps {
    openModalSpeed: MouseEventHandler<HTMLButtonElement>,
    openModalTime: MouseEventHandler<HTMLButtonElement>,
    handlePlay: Function,
    handlePause: Function,
    prevTrack: Function,
    nextTrack: Function,
    handlePauseP: Function,
    track?: string,
    playing?: boolean,
    statusPlayer?: boolean,
    isPhone?: boolean,
    automaticShutdown: AutomaticShutdown,
    speed: string,
    handleUpdateLastActiveTrack: Function,
    startProgres?: number,
    cleanPlayer?: Function,
};

export const PlayerBook: FC<PlayerBookProps> = ({
    openModalSpeed,
    openModalTime,
    prevTrack,
    nextTrack,
    track = '',
    playing = false,
    statusPlayer = true,
    handlePlay,
    handlePause,
    isPhone = false,
    automaticShutdown,
    handlePauseP,
    handleUpdateLastActiveTrack,
    speed,
    startProgres = 0,
    cleanPlayer = () => { }
}) => {
    const [progress, setProgress] = useState<number>(0);
    const [duration, setDuration] = useState<number>(0);
    const [volume, setVolume] = useState<number>(50);

    const playerRef = useRef<ReactPlayer | null>(null);
    const useWrPropgress = useRef<HTMLDivElement>(null);
    const conVolumeCurent = useRef<number>(50);

    const handleSavePos = () => {
        handleUpdateLastActiveTrack(((duration * (progress * 100)) / 100), progress);
    }
    const handlePlayPlayer = () => handlePlay();
    const handlePausePlayer = () => {
        handleSavePos();
        handlePause(false)
    };
    const handleProgress = (state: any) => {
        if (state.played >= 1) {
            handlePauseP();
            handleSavePos();
        }
        setProgress(state.played);
    };
    const handleDuration = (duration: number) => setDuration(duration);
    const handleSeek = (seek: number) => playerRef.current?.seekTo(seek);
    const handleClickSeek = (e: any) => {
        let widthD = useWrPropgress.current?.offsetWidth ? useWrPropgress.current?.offsetWidth : 1;
        let x = e.pageX - (useWrPropgress.current?.offsetLeft ? useWrPropgress.current?.offsetLeft : 0);
        let valueSeek: number = ((x * 100) / widthD) / 100;

        handleSavePos();
        handleSeek(valueSeek);
        setProgress(valueSeek);
    }
    const squanderPrev = () => {
        if (!!duration) {
            let intres = (progress - seconByInteres(duration));

            if (intres <= 0) {
                intres = 0;
                // handlePausePlayer();
                prevTrack();
            }
            handleSavePos();
            handleSeek(intres);
            setProgress(intres);
        }
    }

    const squanderNext = () => {
        if (!!duration) {
            let intres = (progress + seconByInteres(duration));

            if (intres >= 1) {
                intres = 1;
                // handlePausePlayer();
                nextTrack();
            }

            handleSavePos();
            handleSeek(intres);
            setProgress(intres);
        }
    }

    const hanNex = () => {
        nextTrack();
        handleSavePos();
    }

    const hanPrev = () => {
        prevTrack();
        handleSavePos();
    }

    const handleVolume = (e: any, newValue: number) => {
        if (e === 'non') conVolumeCurent.current = volume;
        if (e === 'val') {
            setVolume(conVolumeCurent.current);
            return;
        }

        setVolume(newValue);
    };

    useEffect(() => {
        if (startProgres > 0)
            handleSeek(startProgres / 100);
    }, [startProgres]);

    useEffect(() => {
        handleSavePos();
    }, [track]);

    return (
        <div className="book-player-wr">
            <button className="book-player-close" onClick={() => cleanPlayer()}>
                <IconSprite id="close__btn_player" />
            </button>
            <div className="player-book">
                <ReactPlayer
                    ref={playerRef}
                    url={track}
                    playing={playing && statusPlayer}
                    volume={volume / 100}
                    muted={false}
                    playbackRate={Number(speed)}
                    onPlay={handlePlayPlayer}
                    onPause={() => handlePausePlayer}
                    onProgress={handleProgress}
                    onDuration={handleDuration}
                />
            </div>
            <div className="container">
                <div className={`play ${(track.length === 0) ? "no-track" : ""}`}>
                    <div className="play__informat play-inform">
                        <div className="play-inform__time play-inform__time_plus">{formatTime(duration)}</div>
                        <div className="play-inform__progress" ref={useWrPropgress} onClick={handleClickSeek}>
                            <Propgress
                                phone={isPhone}
                                interes={progress}
                            />
                        </div>
                        <div className="play-inform__time play-inform__time_minus">-{formatTime(duration, true, progress)}</div>
                    </div>
                    <div className="play__panel play-panel">
                        {
                            !isPhone && <div className="play-panel__left play-panel__col">
                                <button className="btn-play-24" title="Оберіть швидкість прослуховування" onClick={openModalSpeed}>
                                    <IconSprite id={(speed === '1') ? "icon_player_speed" : "icon_player_speed_active"} />
                                </button>
                                <button className="btn-play-24" title="Налаштуйте автоматичне виключення" onClick={openModalTime}>
                                    <IconSprite id={automaticShutdown.status ? "icon_player_sleep_active" : "icon_player_sleep"} />
                                </button>
                            </div>
                        }
                        <div className="play-panel__center">
                            <button className="btn-play-24" title="Попередня" onClick={() => hanPrev()}>
                                <IconSprite id={"icon_player_prev"} />
                            </button>
                            <button className="btn-play-24" title="Перемотка, назад" onClick={squanderPrev}>
                                <IconSprite id={"icon_player_squander_prev"} />
                            </button>
                            <button className="btn-play-48" onClick={() => playing ? handlePausePlayer() : handlePlayPlayer()}>
                                <IconSprite id={playing ? "icon_player_pause" : "icon_player_play"} />
                            </button>
                            <button className="btn-play-24" title="Перемотка, перед" onClick={squanderNext}>
                                <IconSprite id={"icon_player_squander_next"} />
                            </button>
                            <button className="btn-play-24" title="Наступна" onClick={() => hanNex()}>
                                <IconSprite id={"icon_player_next"} />
                            </button>
                        </div>
                        {
                            !isPhone && <div className="play-panel__right play-panel__col">
                                <Volume
                                    value={volume}
                                    handleChange={handleVolume}
                                />
                            </div>
                        }
                        {
                            isPhone && <div className="play-panel__mob">
                                <button className="btn-play-24" title="Оберіть швидкість прослуховування" onClick={openModalSpeed}>
                                    <IconSprite id={(speed === '1') ? "icon_player_speed" : "icon_player_speed_active"} />
                                </button>
                                <Volume
                                    value={volume}
                                    handleChange={handleVolume}
                                />
                                <button className="btn-play-24" title="Налаштуйте автоматичне виключення" onClick={openModalTime}>
                                    <IconSprite id={automaticShutdown.status ? "icon_player_sleep_active" : "icon_player_sleep"} />
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}