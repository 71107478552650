import { FC, useEffect, useRef } from "react";

import { Card } from "./card";

import { closeNotification } from "../../stores/slices/notifications";
import { useAppDispatch, useAppSelector } from "hooks/state";

export interface NotificationsPtops { };

export const Notifications: FC<NotificationsPtops> = () => {
    const { list } = useAppSelector(state => state.notification);
    const refIdTime: { current: NodeJS.Timeout | null } = useRef(null);

    const dispatch = useAppDispatch();
    const handleCloseClasByid = (id: number) => dispatch(closeNotification(id));

    useEffect(() => {
        if (list.length == 0) return;

        if (refIdTime.current) clearInterval(refIdTime.current as NodeJS.Timeout);

        refIdTime.current = setTimeout(() => {
            dispatch(closeNotification(list[list.length - 1].id));
        }, 3e3);
    }, [list]);

    return (
        <div className="notification">
            {
                list.map(item => (
                    <Card
                        key={item.id}
                        id={item.id}
                        text={item.message}
                        icon={item.icon}
                        handleClose={handleCloseClasByid}
                    />
                ))
            }
        </div>
    )
}