import { FC, ChangeEvent, useRef, useState } from "react";

import { EdipPhotoAvatar } from "../editPhotoAvatar";
import { IconSprite } from "../iconSprite";
import { Button } from "../button";

import { useAppDispatch } from "hooks/state";
import { profileEditAvatar, profileEditAvatarBase } from "../../stores/controlers/profile";

export interface AvatarProps {
    handleClose: Function,
    handleGenerat: Function,
    src: string,
};

export const ModalAvatar: FC<AvatarProps> = ({
    handleClose,
    handleGenerat,
    src
}) => {
    const dispatch = useAppDispatch();
    const [filee, setFile] = useState<File | undefined>(undefined);
    const [edit, setEdit] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const refFile = useRef<HTMLInputElement>(null);

    const handleCloseEdit = () => setEdit(false);

    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setErrorMessage('');
            let file = e.target.files[0];

            if (file?.size > 2048000) {
                setErrorMessage('Максимальний розмір файлу 2 МБ.');
                return;
            }

            setFile(file);
            await dispatch(profileEditAvatar({ avatarCode: file }));
            await dispatch(profileEditAvatarBase({ avatarCode: '' }))
        }
    }

    return (
        <div className={`hystmodal hystmodal_avatar`}>
            <div className="hystmodal__wrap">
                <div className="hystmodal__op" onClick={() => handleClose()}></div>
                <div className="hystmodal__window">
                    <button className="hystmodal__close" onClick={() => handleClose()}>
                        <IconSprite id='close_modal' />
                    </button>
                    <div className="hystmodal__content">
                        <div className="mod-ava">
                            <div className="mod-ava__pad">
                                <div className="mod-ava__title">Зміна фотографії</div>
                                <div className="mod-ava__des">Для завантаження зображення необхідно обрати потрібне зображення з персональної галереї або вибрати із запропонованих  в одному з доступних форматів  JPEG або PNG. Максимальний розмір файлу 2 МБ.</div>
                            </div>
                            <div className="mod-ava___wr">
                                {
                                    !!edit && <EdipPhotoAvatar
                                        fileImg={!!filee ? URL.createObjectURL(filee) : undefined}
                                        handleCloseEdit={handleCloseEdit}
                                        handleGenerat={handleGenerat}
                                    />
                                }
                                {
                                    !edit && <>
                                        <div className={`avat ${!!src ? "active" : ""}`}>
                                            <label className="avat__lab" >
                                                {!!src && <img className="avat__img" src={src} alt="Фото профіля" />}
                                                <span className="avat__t"> {!!src ? "Змінити фото" : "Додати фото"}</span>
                                                <input type="file" accept="image/png, image/jpeg" style={{ display: 'none' }} ref={refFile} onChange={handleFileChange} />
                                            </label>
                                        </div>
                                        {
                                            (errorMessage.length > 0) && <div className="mod-ava_error">{errorMessage}</div>
                                        }
                                        <div className="mod-ava__wr-btn">
                                            <Button label="Редагувати" disabled={!filee} handleClick={() => setEdit(true)} />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}