import { FC, useState, useEffect } from "react";

import { Banner } from "../components/banner";
import { Filter } from "../components/filter";
import { CardRecent } from "../components/cardRecent";
import { Pagination } from "../components/pagination";
import { NoList } from "../components/noList";
import { Faqs } from "../components/faq";

import { useAppDispatch, useAppSelector } from "hooks/state";
import { useFilter } from "../hooks/filter";
import { getAllLastBooks } from "../stores/controlers/books";
import { addFavorite, detachFavorite } from "../stores/controlers/book";
import { searchLastFile } from "../units/players";

export interface RecentActivityProps { };

export const RecentActivityPage: FC<RecentActivityProps> = () => {
    const [firts, setFirst] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { handleFilter, getFilter, searchParams } = useFilter();
    const { theme, books: { bookLastActiveCards, paginationLastActive, loadGetCardBooksLast }, faqs } = useAppSelector((state: any) => state);

    const handlePage = (v: number) => {
        handleFilter({ key: 'page', name: `${v}` });
    }

    const handleFavorite = (id: number, status: boolean | number) => {
        if (!status)
            dispatch(addFavorite({ id, filter: getFilter(), allPage: 'active' }));

        if (status)
            dispatch(detachFavorite({ id, filter: getFilter(), allPage: 'active' }));
    }

    useEffect(() => {
        if (!firts)
            setFirst(true);

        if (firts) dispatch(getAllLastBooks({
            filter: getFilter()
        }));
    }, [firts, searchParams, searchParams]);

    return (
        <div className="global-page-ptop">
            <Banner />
            <Filter
                title="Прослуховуються"
                handleFilter={handleFilter}
                activeAuthor={searchParams.get('author') || ''}
                activeTime={searchParams.get('size') || ''}
                activeGenres={searchParams.get('genres') || ''}
                activeСollection={searchParams.get('archive_id') || ''}
                lenBook={1}
            />
            <div className="wr-grid-audio wr-grid-audio__recent">

                <div className="container wr-grid-audio_cont">
                    {
                        ((bookLastActiveCards.length === 0) && !loadGetCardBooksLast) && (
                            <NoList
                                title="Немає аудіокниг, що прослуховуються..."
                                text="Поверніться в розділ “Мої аудіокниги”, додайте книгу та насолоджуйтеся прослуховуванням."
                            />
                        )
                    }
                    {
                        loadGetCardBooksLast && [...new Array(2)].map((_, index) => (
                            <div className="card_load card_load_recent" key={index}></div>
                        ))
                    }
                    {
                        !loadGetCardBooksLast && bookLastActiveCards.map((item: any) => (
                            <CardRecent
                                key={item.id}
                                id={item.id}
                                img={item.full_path_photo || ''}
                                file={searchLastFile(item.lastActivity.file_name, item.urlBookFiles)}
                                title={item.title}
                                des={item.description}
                                date={item.lastActivity.time}
                                favorite={item.isFavorite}
                                isPhone={theme.phone}
                                author={item.author}
                                fileList={item.urlBookFiles}
                                nameBookFiles={item.nameBookFiles}
                                totalDuration={item.lastActivity.total_duration}
                                stopDuration={item.lastActivity.stop_duration}
                                progress={item.lastActivity.progress}
                                startPropgress={item.lastActivity.track_percentage}
                                lastFile={item.lastActivity.file_name}
                                handleFavorite={handleFavorite}
                            />
                        ))
                    }
                </div>
                {
                    !loadGetCardBooksLast && (paginationLastActive?.last_page !== undefined) && (Number(paginationLastActive.last_page) > 1) && (
                        <div className="container">
                            <Pagination
                                handlePage={handlePage}
                                activePage={Number(paginationLastActive.current_page)}
                                pages={Number(paginationLastActive.last_page)}
                            />
                        </div>
                    )
                }
                <Faqs
                    title="Питання що часто задаються"
                    subTitle=""
                    data={faqs.recent}
                />
            </div>
        </div>
    )
}