import { FC } from "react";

import { ItemFaq } from "./item";

interface FaqProps {
    title?: string,
    subTitle?: string,
    data?: { id: number, question: string, answer: string }[]
};

export const Faqs: FC<FaqProps> = ({
    title = '',
    subTitle = '',
    data = []
}) => {
    if (data.length == 0)
        return <></>;

    return (
        <div className="faqs">
            <div className="container">
                {
                    (title.length > 0) && <h2 className="faqs__title">{title}</h2>
                }
                <div className="faqs__wr">
                    {(subTitle.length > 0) && <h3 className="faqs-subtitle">{subTitle}</h3>}
                    {
                        data.map(item => (
                            <ItemFaq
                                key={item.id}
                                title={item.answer}
                                des={item.question}
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}