import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppDispatch, RootState } from "../index";

import api from "../../api";

export const getFilterGenres = createAsyncThunk<
    {},
    undefined,
    { dispatch: AppDispatch, state: RootState }
>('filter/getGenres',
    async (_, { dispatch, getState }) => {
        const res = await api.filterApi.getFilterGnres();

        return res?.data ? res.data : [];
    }
)

export const getFilterAuthors = createAsyncThunk<
    {},
    undefined,
    { dispatch: AppDispatch, state: RootState }
>('filter/getAuthor',
    async (_, { dispatch, getState }) => {
        const res = await api.filterApi.getFilterAuthors();

        return res?.data ? res.data : [];
    }
)

export const getFilterCollections = createAsyncThunk<
    {},
    undefined,
    { dispatch: AppDispatch, state: RootState }
>('filter/getCollections',
    async (_, { dispatch, getState }) => {
        const res = await api.filterApi.getFilterCollections();

        return res?.data ? res.data : [];
    }
)

