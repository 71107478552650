import cookies from 'js-cookie';

export const setCookeToken = (value: string) => {
    return cookies.set('token', value, { expires: 365 });
}

export const getCookeToken = () => {
    return cookies.get('token');
}

export const removeCookeToken = () => {
    return cookies.remove('token');
}