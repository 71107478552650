import React, { FC, useEffect } from "react";
import { useIMask } from 'react-imask';

import { FieldInput } from "./fieldInput";

export interface FieldInputCodeProps {
    placeholder?: string,
    name?: string,
    errorMessage?: string,
    valueIn?: string,
    handleChange?: Function
};

export const FieldInputCode: FC<FieldInputCodeProps> = ({
    valueIn,
    ...props
}) => {
    const {
        ref,
        value,
        setValue,
    } = useIMask({ mask: '00 00 00 00' }, /* { onAccept, onComplete } */);

    useEffect(() => {
        if (typeof valueIn == 'string')
            setValue(valueIn);
    }, [valueIn]);

    return (
        <FieldInput
            refe={ref}
            value={value}
            {...props}
        />
    )
}