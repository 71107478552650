import { FC } from "react";

interface IconSpriteProps {
    id: string
};

export const IconSprite: FC<IconSpriteProps> = ({ id }) => {
    return (
        <svg className="icon-sprite">
            <use xlinkHref={`/sprite.svg#${id}`}></use>
        </svg>
    )
}