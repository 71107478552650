import { FC, useEffect } from "react";
import { useIMask } from 'react-imask';

import { FieldInput } from "./fieldInput";

export interface FieldInputDataProps {
    placeholder?: string,
    name?: string,
    errorMessage?: string,
    valueIn?: string,
    handleChange?: Function,
    required?: boolean,
    requireText?: string
};

export const FieldInputData: FC<FieldInputDataProps> = ({
    valueIn,
    ...props
}) => {
    const {
        ref,
        value,
        setValue,
    } = useIMask({
        mask: Date,
        min: new Date(1940, 0, 1),
        max: new Date(2015, 0, 1),
    });

    useEffect(() => {
        if (typeof valueIn == 'string')
            setValue(valueIn);
    }, [valueIn]);

    return (
        <FieldInput
            refe={ref}
            value={value}
            {...props}
        />
    )
}