import { FC, MouseEventHandler, useState } from "react";

import { HeadForm } from "./headForm";
import { FieldInputPhone } from "../fieldInputPhone";
import { CheckedTerms } from "../checkedTerms";
import { Button } from "../button";

import { validFormUp, validPhone } from "../../units/valids";

import { FormUpInterface } from "./forms";

export interface RegistrationProps {
    formUp: FormUpInterface,
    setFormUp: Function,
    send: Function,
    loadBtn: boolean
};

export const Registration: FC<RegistrationProps> = ({
    formUp,
    setFormUp,
    send,
    loadBtn
}) => {
    const [error, setError] = useState<string>('');
    const updateSet = ({ name, value }: { name: string, value: string | boolean }) => {
        setFormUp((prev: object) => ({ ...prev, ...{ [name]: value } }));
    }

    const handleEnt = (e: any) => {
        if (e.keyCode == 13) {
            if (!validPhone(formUp.phone)) {
                setError('Формат телефону не вірний');
            }

            if (validPhone(formUp.phone)) {
                setError('');
            }

            if (validFormUp(formUp)) {
                send();
            }
        }
    }

    return (
        <div className="aform__registr">
            <HeadForm desc="Увійдіть та користуйтеся платформою зручно!" />
            <div className="aform__m-360 aform_gap aform_gap_18 aform_registr">
                <FieldInputPhone
                    name='phone'
                    placeholder='Ваш номер телефону'
                    valueIn={formUp.phone}
                    handleChange={(value: string) => updateSet({ name: 'phone', value })}
                    onEnter={handleEnt}
                    errorMessage={error}
                />
                <CheckedTerms checked={formUp.terms} val={formUp.terms} handleChange={(value: boolean) => updateSet({ name: 'terms', value: !value })} />
                <div className="aform__wr_btn">
                    <Button label="Увійти" load={loadBtn} disabled={!validFormUp(formUp)} handleClick={() => send()} />
                </div>
            </div>
        </div>
    )
}