import { FC, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

import { MenuHead } from "./menu";
import { Profile } from "./profile";
import { MobMenu } from "./mobMenu";
import { IconSprite } from "../iconSprite";

import { useAppSelector, useAppDispatch } from '../../hooks/state';
import { setShowModalProfile, setCloseModalProfile, setCloseModalAll, setCloseMobMenu, setOpenMobMenu } from "../../stores/slices/theme";
import { setCloseModal, setModalOpen } from "../../stores/slices/modals";

import { exitProfile } from "../../stores/controlers/sessions";

import imgLogo from "../../assets/img/logo.svg";

export interface HeaderProps { };

export const Header: FC<HeaderProps> = () => {
    const dispatch = useAppDispatch();
    const { theme, modals, session, profile } = useAppSelector((state) => state);
    const navigate = useNavigate();

    const ava: string = session.auth ?
        !!profile.data.avatarBase64?.length ?
            (!!(profile.data.avatarBase64 || '') ? profile.data.avatarBase64 || '' : '') :
            (!!(profile.data.urlAvatar || '') ? profile.data.urlAvatar || '' : '')
        : '';

    const openModal = () => {
        modals.show && dispatch((setCloseModal()));

        dispatch(setShowModalProfile());
    }
    const closeModal = () => dispatch(setCloseModalProfile());
    const handleExit = () => exitProfile(dispatch);
    const clickFun = () => dispatch(setCloseModalAll());
    const closeMobMenu = () => {
        dispatch(setCloseMobMenu());
        clickFun();
    };
    const handleOpenFaq = () => {
        closeModal();
        dispatch(setModalOpen('faq'));
        if (theme.phone)
            dispatch(setCloseMobMenu());
    }

    return (
        <header className="header">
            <div className="header__row">
                {
                    theme.phone && (
                        <div className="header_btn_burger">
                            {session.auth && <button onClick={() => theme.showMobMenu ? closeMobMenu() : dispatch(setOpenMobMenu())}><IconSprite id="icon_burger" /></button>}
                            {!session.auth && <button onClick={() => navigate(-1)}><IconSprite id="icon_arrow_back" /></button>}
                        </div>
                    )
                }
                <div className="header__logo">
                    <Link to="/"><img src={imgLogo} alt="Логотип" /></Link>
                </div>
                {
                    !theme.phone && (
                        <div className="header__wr-menu">
                            {
                                session.auth && (
                                    <MenuHead clickFun={clickFun} />
                                )
                            }
                        </div>
                    )
                }
                <div className="header__right">
                    <Profile
                        profile={profile.data}
                        auth={session.auth}
                        firstNameSymbol={!!profile?.data?.firstName?.length ? profile.data.firstName.charAt(0) : 'O'}
                        src={ava}
                        openModal={openModal}
                        closeModal={closeModal}
                        handleOpenFaq={handleOpenFaq}
                        handleExit={handleExit}
                        show={theme.showModalProfile}
                        isPhone={theme.phone}
                    />
                </div>
            </div>

            {
                theme.phone && (
                    <MobMenu
                        show={theme.showMobMenu}
                        closeMenu={closeMobMenu}
                        handleOpenFaq={handleOpenFaq}
                    />
                )
            }
        </header>
    )
}