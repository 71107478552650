import { FC, useEffect, useState } from 'react';

import { Button } from "../button";
import { SelectList } from '../selectList';

import { useAppDispatch, useAppSelector } from 'hooks/state';
import { updateAutomaticShut } from "../../stores/slices/player";
import { addNotification } from "../../stores/slices/notifications";
import { setCloseModal } from "../../stores/slices/modals";

import { dataEndStop } from "../../units/data";

import dataSelect from "../../constants/dataSelects.json";

export interface ModalExlusionProps { };

export const ModalExlusion: FC<ModalExlusionProps> = () => {
    const [stateSelect, setStateSelect] = useState<string>('auto');
    const [disabled, setDisabled] = useState<boolean>(true);
    const dispatch = useAppDispatch();
    const { automaticShutdown } = useAppSelector(state => state.player);

    const handle = (e: string) => {
        setStateSelect(e);
    }

    const send = () => {
        let st = (stateSelect == "auto") ? false : true;

        dispatch(updateAutomaticShut({
            status: st,
            select: stateSelect,
            dateStop: !st ? null : dataEndStop(stateSelect)
        }));

        dispatch(addNotification({
            message: !st ? `Автоматичне виключення вимкнено!` : `Автоматичне виключення через ${stateSelect} хвилин!`,
            icon: 'icon_notif_book'
        }));

        dispatch(setCloseModal());
    }

    useEffect(() => {
        setStateSelect(automaticShutdown.select);
    }, []);

    useEffect(() => {
        setDisabled(stateSelect === automaticShutdown.select);
    }, [stateSelect]);

    return (
        <div className="modal-content modal-content_386 g-modal-20">
            <div className="modal__title modal__title_icon text_center font_w_500"><span>Налаштуйте автоматичне виключення</span></div>
            <SelectList
                list={dataSelect.time}
                activeKey={stateSelect}
                handle={handle}
            />
            <div className="modal-wr-btn-one">
                <Button label="Слухати" disabled={disabled} handleClick={() => send()} />
            </div>
        </div>
    )
}