import { FC, useEffect, useState } from "react";

import { Banner } from "../components/banner";
import { Filter } from "../components/filter";
import { CardAudio } from "../components/cardAudio";
import { Pagination } from "../components/pagination";
import { NoList } from "../components/noList";
import { Faqs } from "../components/faq";

import { useAppDispatch, useAppSelector } from "../hooks/state";
import { getAllBooksFavorites } from "../stores/controlers/books";
import { detachFavorite } from "../stores/controlers/book";

import { useFilter } from "../hooks/filter";

export interface FavoritesPageProps { };

export const FavoritesPage: FC<FavoritesPageProps> = () => {
    const { handleFilter, getFilter, searchParams } = useFilter();
    const [firts, setFirst] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { books: { bookFavoriteCards, paginationFavorite, loadGetCardBooksFavorites }, faqs } = useAppSelector((state: any) => state);

    const handlePage = (v: number) => {
        handleFilter({ key: 'page', name: `${v}` });
    }

    const handleFavorite = (id: number) => {
        dispatch(detachFavorite({ id, filter: getFilter(), allPage: 'fav' }));
    }

    useEffect(() => {
        if (!firts)
            setFirst(true);

        if (firts) dispatch(getAllBooksFavorites({
            filter: getFilter()
        }));
    }, [firts, searchParams, searchParams]);

    return (
        <div className="global-page-ptop page-p-b">
            <Banner />
            <Filter
                title="Улюблені"
                handleFilter={handleFilter}
                activeAuthor={searchParams.get('author') || ''}
                activeTime={searchParams.get('size') || ''}
                activeGenres={searchParams.get('genres') || ''}
                activeСollection={searchParams.get('archive_id') || ''}
                lenBook={1}
            />
            <div className="wr-grid-audio">
                <div className="container wr-grid-audio_cont">
                    {
                        ((bookFavoriteCards.length === 0) && !loadGetCardBooksFavorites) && (
                            <NoList
                                title="Немає  улюблених аудіокниг..."
                                text="Поверніться в розділ “Мої аудіокниги”, додайте книгу та насолоджуйтеся прослуховуванням."
                            />
                        )
                    }
                    <div className="grid-cards-aydio">
                        {
                            loadGetCardBooksFavorites && [...new Array(3)].map((_, index) => (
                                <div className="card_load" key={index}></div>
                            ))
                        }
                        {
                            !loadGetCardBooksFavorites && bookFavoriteCards.map((itemCard:any, index:number) => (
                                <CardAudio
                                    key={index}
                                    id={itemCard.id}
                                    img={itemCard?.full_path_photo || ''}
                                    file={itemCard?.urlBookFiles[0]?.name}
                                    title={itemCard.title}
                                    des={itemCard.author}
                                    performer={itemCard.voice_acting}
                                    year={itemCard.year}
                                    fileType={itemCard.isbn}
                                    fileSize={itemCard.size}
                                    time={itemCard.duration}
                                    fileList={itemCard.urlBookFiles}
                                    favorite={true}
                                    handleFavorite={() => handleFavorite(Number(itemCard.id))}
                                    nameBookFiles={itemCard.nameBookFiles}
                                />
                            ))
                        }
                    </div>
                </div>
                {
                    !loadGetCardBooksFavorites && (paginationFavorite?.last_page !== undefined) && (Number(paginationFavorite.last_page) > 1) && (
                        <div className="container">
                            <Pagination
                                handlePage={handlePage}
                                activePage={Number(paginationFavorite.current_page)}
                                pages={Number(paginationFavorite.last_page)}
                            />
                        </div>
                    )
                }
                <Faqs
                    title="Питання що часто задаються"
                    subTitle=""
                    data={faqs.favorite}
                />
            </div>
        </div>
    )
}