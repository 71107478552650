import { FC, useEffect } from "react";
import { FieldInput } from "./fieldInput";

const reg = /[а-яА-ЯёЁІі]/g;

export interface FieldInputEmailProps {
    placeholder?: string,
    name?: string,
    errorMessage?: string,
    value?: string,
    handleChange?: Function,
    required?: boolean,
    requireText?: string
};

export const FieldInputEmail: FC<FieldInputEmailProps> = ({
    handleChange = () => { },
    ...props
}) => {
    const onHandleChangle = (value: string) => {
        let val = value.trim().toLowerCase();

        if (val.search(reg) != -1) {
            handleChange(val.replace(reg, ''));
            return;
        }

        handleChange(val);
    }

    return (
        <FieldInput
            handleChange={onHandleChangle}
            {...props}
        />
    )
}