import { FC } from "react";

import imgLogo from "../../assets/img/logo.svg";

export interface HeadProps { };

export const Head: FC<HeadProps> = () => {
    return (
        <div className="auth__header">
            <div className="auth__logo">
                <img src={imgLogo} alt="Логотип" />
            </div>
        </div>
    )
}