import { FC, MouseEventHandler } from "react";

export interface ButtonProps {
    label: string,
    disabled?: boolean,
    type?: string,
    handleClick: MouseEventHandler<HTMLButtonElement>,
    load?: boolean,
};

export const Button: FC<ButtonProps> = ({
    label,
    type = 'd',
    disabled = false,
    load = false,
    handleClick = () => { }
}) => {
    return (
        <button
            className={`btn btn_${type} ${load ? 'load' : ''}`}
            type="button"
            disabled={disabled}
            onClick={handleClick}>
            <span>{label}</span>
        </button>
    )
}