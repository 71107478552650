import { FC, MouseEventHandler, useState } from "react";
import { Link } from "react-router-dom";

import { IconSprite } from "./iconSprite";

import { timeCard } from "../units/timeOut";

import routers from "../constants/routers.json";

export interface BookInfoProps {
    handleDownload: MouseEventHandler<HTMLButtonElement>,
    isPhone: boolean,
    favorite?: boolean,
    description: string,
    title: string,
    author: string,
    performer: string,
    year: string,
    fileType: string,
    fileSize: string,
    time: number | null,
    handlePlay: Function,
    img: string,
    handleFavorite?: Function,
    islast: boolean
};
export const BookInfo: FC<BookInfoProps> = ({
    handleDownload,
    isPhone,
    favorite = false,
    description,
    title,
    author,
    performer,
    year,
    fileType,
    fileSize,
    time,
    handlePlay,
    img,
    handleFavorite = () => { },
    islast
}) => {
    const [allDes, setAllDes] = useState<boolean>(false);
    let des = `${(description.length > (isPhone ? 300 : 700)) ?
        (
            `${description.slice(0, (isPhone ? (allDes ? undefined : 300) : (allDes ? undefined : 700)))}`
        ) : description}`

    des = `${des} ${(!allDes) ? "<span>...</span>" : ""}`;

    return (
        <div className="binfo">
            <div className="binfo-h">
                <div className="books_container_content">
                    <div className="binfo-h_im"></div>
                    <div className="binfo-h_w binfo_wid">
                        <div className="crumbs">
                            <ul>
                                <li>
                                    <Link to={routers.home.link}>Мої аудіокниги</Link>
                                </li>
                                <li>
                                    <span>{title}</span>
                                </li>
                            </ul>
                        </div>
                        <h1 className="binfo-h__title"><span className="binfo-h__title_first">{title}</span> <span className="binfo-h__title_tr">-</span> <span className="binfo-h__title_last">{author}</span></h1>
                    </div>
                </div>
            </div>
            <div className="binfo-m">
                <div className="books_container_content binfo-m_row">
                    <div className="binfo-m__left">
                        <div className="binfo-m__img">
                            <button className="binfo-m__bnt-favor" onClick={() => handleFavorite()}>
                                <IconSprite id={favorite ? "icon_panel_card_heart_floodet" : "icon_panel_card_heart"} />
                            </button>
                            {
                                !!img.length && <img src={img} />
                            }
                        </div>
                        <div className="binfo-m__left-btn">
                            <button className="btn btn_d btn-book-dow" onClick={() => handlePlay()}>
                                {
                                    !isPhone && <IconSprite id='icon_headphones' />
                                }
                                <span>{isPhone ? (islast ? "Слухати далі" : "Слухати книгу") : (islast ? "Слухати далі" : "Слухати аудіокнигу")}</span>
                            </button>
                            <button className="btn btn_b btn-book-dow_two" onClick={handleDownload}>
                                {isPhone ? "Скачати книгу" : <IconSprite id='icon_arrow_down' />}
                            </button>
                        </div>
                    </div>
                    <div className="binfo-m_cont binfo_wid">
                        <div className="binfo-m__title">{isPhone ? "ОПИС" : "Класика"}</div>
                        <div className="binfo-m__des">
                            <p dangerouslySetInnerHTML={{
                                __html: des
                            }}>
                            </p>
                            {
                                (description.length > (isPhone ? 300 : 700)) ?
                                    <button onClick={() => setAllDes(prev => !prev)}>{allDes ? "Читати менше" : "Читати більше"}</button>
                                    : <span></span>
                            }
                        </div>
                        <div className="binfo-m__items">
                            {
                                !!performer?.length && (
                                    <div className="m-item">
                                        <IconSprite id='icon_into_card_performer' />
                                        <span>{performer}</span>
                                    </div>
                                )
                            }
                            {
                                !!year?.length && (
                                    <div className="m-item">
                                        <IconSprite id='icon_into_card_pencil' />
                                        <span>{year}</span>
                                    </div>
                                )
                            }
                            {
                                !!fileType?.length && (
                                    <div className="m-item">
                                        <IconSprite id='icon_into_card_file' />
                                        <span>{fileType}</span>
                                    </div>
                                )
                            }
                            {
                                !!fileSize?.length && (
                                    <div className="m-item">
                                        <IconSprite id='icon_into_card_size' />
                                        <span>{fileSize}</span>
                                    </div>
                                )
                            }
                            {
                                !!time && (
                                    <div className="m-item">
                                        <IconSprite id='icon_into_card_time' />
                                        <span>{timeCard(time)}</span>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}