import { FC, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

import { Avatar } from "../components/avatar";
import { IconSprite } from "../components/iconSprite";
import { FieldInput } from "../components/fieldInput";
import { FieldInputEmail } from "../components/fieldInputEmail";
import { FieldInputData } from "../components/fieldInputData";
import { FieldInputPhone } from "../components/fieldInputPhone";
import { Button } from "../components/button";

import { useAppSelector, useAppDispatch } from '../hooks/state';
import { exitProfile, } from "../stores/controlers/sessions";
import { profileEdit } from "../stores/controlers/profile";
import { updateField } from "../stores/slices/profile";

import {
    validSettingsProfile,
    validData,
    validEmailRequired,
    validNameRegular,
    dopAllValidEmail
} from "../units/valids";

import routers from "../constants/routers.json";

export interface EditProfilePageProps { };
export interface ErrorDopField {
    firstName: boolean,
    lastName: boolean,
    data: boolean,
    email: boolean,
}

export const EditProfilePage: FC<EditProfilePageProps> = () => {
    const [dopErrorFiedls, setDopErrorFiedls] = useState<ErrorDopField>({ firstName: true, lastName: true, data: true, email: true });
    const [errorEmail, setErrorEmail] = useState<string>("Будь ласка, введіть коректну електронну адресу!");

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { profile } = useAppSelector((state) => state);

    const handleExit = () => exitProfile(dispatch);
    const updateSet = ({ name, value }: { name: string, value: string }) => dispatch(updateField({ name, value }));

    const sendHandleClick = () => {
        let objErrorFields: ErrorDopField = {
            firstName: validNameRegular(profile.data.firstName || ''),
            lastName: validNameRegular(profile.data.lastName || ''),
            data: validData(profile.data.data || ''),
            email: validEmailRequired(profile.data.email || ''),
        }

        dopAllValidEmail({ value: profile.data?.email || "", error: !dopErrorFiedls.email, set: setErrorEmail });

        setDopErrorFiedls(prev => ({
            ...prev,
            ...objErrorFields
        }));

        let statusError = objErrorFields.firstName &&
            objErrorFields.lastName &&
            objErrorFields.data &&
            objErrorFields.email;

        if (statusError)
            dispatch(profileEdit({ page: true, setErrorEmail: handleErrorEmail }));
    }

    const handleErrorEmail = (value: string) => {
        console.log(value);
        setDopErrorFiedls(prev => ({
            ...prev,
            ...{ email: false }
        }));

        setErrorEmail(value);
    }

    return (
        <div className="settings">
            <div className="container">
                <div className="settings__content">
                    <button className="settings__btn_back" onClick={() => navigate(-1)}>
                        <IconSprite id='icon_arrow_back' />
                    </button>
                    <div className="settings__ava">
                        <Avatar src={!!profile.data.avatarBase64?.length ? profile.data.avatarBase64 || '' : profile.data.urlAvatar || ''} />
                    </div>
                    <div className="settings__name text_center">{profile.data.firstName || ''} {profile.data.lastName || ''}</div>
                    <div className="settings__form">
                        <div className="settings__form--t">Заповніть Ваші персональні дані</div>
                        <div className="settings__form__row">
                            <FieldInput
                                name="FirstName"
                                placeholder="Імʼя"
                                errorMessage={!dopErrorFiedls.firstName ? "Формат введення імені не має містити в собі такі елементи !,#,$,% а також не латиницю" : ""}
                                value={profile.data.firstName || ''}
                                handleChange={(value: string) => updateSet({ name: 'firstName', value })}
                            />
                            <FieldInput
                                name="lastName"
                                placeholder="Прізвище"
                                errorMessage={!dopErrorFiedls.lastName ? "Формат введення прізвища не має містити в собі такі елементи !,#,$,% а також не латиницю" : ""}
                                value={profile.data.lastName || ''}
                                handleChange={(value: string) => updateSet({ name: 'lastName', value })}
                            />
                        </div>
                        <div className="settings__form__row">
                            <FieldInputPhone
                                name='phone'
                                placeholder='Номер телефону'
                                valueIn={profile.data.phone || ''}
                                handleChange={(value: string) => updateSet({ name: 'phone', value })}
                                disabled={true}
                            />
                            <FieldInputData
                                name="data"
                                placeholder="Дата народження"
                                valueIn={profile.data.data || ''}
                                errorMessage={`${!dopErrorFiedls.data ? "Формат введення дати народження дд.мм.рр" : ""}`}
                                handleChange={(value: string) => updateSet({ name: 'data', value })}
                            />
                        </div>
                        <div className="settings__form__row">
                            <FieldInputEmail
                                name="email"
                                placeholder="Електронна пошта"
                                errorMessage={`${!dopErrorFiedls.email ? errorEmail : ""}`}
                                value={profile.data.email || ''}
                                handleChange={(value: string) => updateSet({ name: 'email', value })}
                            />
                        </div>
                    </div>
                    <div className="settings__btn_send">
                        <Button label="Зберегти" load={profile.loadBtn} disabled={!validSettingsProfile(profile.data)} handleClick={sendHandleClick} />
                    </div>
                    <div className="settings__btn_exit">
                        <button className="h-profile__btn-exit" onClick={() => handleExit()}>
                            <span>Вийти</span>
                            <IconSprite id='exit_profile' />
                        </button>
                    </div>
                    <div className="wr-btn-link-p">
                        <Link to={routers.termsAndConditions.link}>Правила та умови </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}