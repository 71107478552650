import { FC, useEffect, useState } from "react";

import { Registration } from "./registration";
import { Code } from "./code";
import { Login } from "./login";
import { Profile } from "./profile";

import { useAppDispatch } from '../../hooks/state';
import { setWindowForm, FormType } from "../../stores/slices/theme";
import { setDataProfile } from "../../stores/slices/session";
import { CounterState } from "../../stores/slices/profile";
import { contSessAuthCode, contFindOrCreate, resendCode } from "../../stores/controlers/sessions";
import { profileEdit } from "../../stores/controlers/profile";
import { localStorSet, localStorGet } from "../../helpers/localStor";

export interface FormUpInterface { phone: string, terms: boolean };
export interface FormCodeInterface { code: string };
export interface FormloginInterface { phone: string };
export interface FormProfileInterface { avatar: string, firstName: string, lastName: string, data: string, email: string };
export interface FormsProps {
    windowForm: FormType,
    profile: CounterState
};

export const Forms: FC<FormsProps> = ({
    windowForm,
    profile
}) => {
    const dispatch = useAppDispatch();

    const [formUp, setFormUp] = useState<FormUpInterface>({ phone: '', terms: false });
    const [formCode, setFormDode] = useState<FormCodeInterface>({ code: '' });
    const [formLogin, setFormLogin] = useState<FormloginInterface>({ phone: '' });
    const [formProfile, setFormProfile] = useState<FormProfileInterface>({ avatar: '', firstName: '', lastName: '', data: '', email: '' });
    const [loadBtn, setLoadBtn] = useState<boolean>(false);
    const [repeatCode, setRepeatCode] = useState<boolean>(true);
    const [errorCode, setErrorCode] = useState<boolean>(false);
    const [isOneRepeat, setIsOneRepeat] = useState<boolean>(true);

    const handleWindow = (wind: FormType) => {
        dispatch(setWindowForm(wind));
    }

    const upSend = () => {
        localStorSet('phone', formUp.phone);
        contFindOrCreate({
            phone: formUp.phone.replace(/ /g, ''),
            dispatch,
            setLoad: setLoadBtn
        });
    }

    const codeSend = async () => {
        dispatch(contSessAuthCode({ code: formCode.code.replace(/ /g, ''), setLoadBtn, setErrorCode }));
    }

    const handleRepeatCode = () => {
        if ((profile.data.id === null) || !repeatCode) return;
        resendCode({ userId: profile.data.id, dispatch, setRepeatCode, isOneRepeat, setIsOneRepeat });
    }

    const loginSend = () => {
        localStorSet('phone', formLogin.phone);

        contFindOrCreate({
            phone: formLogin.phone.replace(/ /g, ''),
            dispatch,
            setLoad: setLoadBtn,
            registr: false
        });
    }

    const sendProfile = (setErrorEmail: Function) => {
        dispatch(profileEdit({ dataForm: formProfile, setErrorEmail }));
    }

    const missProfile = () => {
        console.log("missProfile");
        dispatch(setDataProfile(true));
    }

    useEffect(() => {
        let localSavePhone = localStorGet('phone');

        if (!!localSavePhone) {
            setFormLogin(prev => ({ ...prev, phone: localSavePhone }));
        }
    }, []);

    useEffect(() => {
        const { firstName, lastName, data, email, avatarBase64, urlAvatar } = profile.data;

        setFormProfile({
            firstName: !!firstName ? firstName : '',
            lastName: !!lastName ? lastName : '',
            data: !!data ? data : '',
            email: !!email ? email : '',
            avatar: !!avatarBase64?.length ? avatarBase64 : !!urlAvatar ? urlAvatar : ''
        });
    }, [windowForm, profile.data.avatarBase64, profile.data.urlAvatar]);

    return (
        <div className="aform">
            <div className="form">
                {
                    (windowForm === 'up') && <Registration
                        formUp={formUp}
                        setFormUp={setFormUp}
                        send={upSend}
                        loadBtn={loadBtn}
                    />
                }
                {
                    (windowForm === 'code') && <Code
                        formCode={formCode}
                        setFormDode={setFormDode}
                        send={codeSend}
                        repeatCode={repeatCode}
                        handleRepeatCode={handleRepeatCode}
                        loadBtn={loadBtn}
                        errorCode={errorCode}
                        isOneRepeat={isOneRepeat}
                        setRepeatCode={setRepeatCode}
                    />
                }
                {
                    (windowForm === 'login') && <Login
                        formLogin={formLogin}
                        setFormLogin={setFormLogin}
                        handleWindow={handleWindow}
                        send={loginSend}
                        loadBtn={loadBtn}
                    />
                }
                {
                    (windowForm === 'profile') && <Profile
                        formProfile={formProfile}
                        setFormProfile={setFormProfile}
                        sendProfile={sendProfile}
                        missProfile={missProfile}
                        loadBtn={profile.loadBtn}
                    />
                }
            </div>
        </div>
    )
}