import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface bookFile {
    duration: number | string,
    name: string,
    size: number | string,
}

export interface PlayCard {
    id: string | number | null,
    status: boolean,
    audioUrl: string,
    statusPlayer?: boolean,
    listFile?: bookFile[] | [],
    nameBookFiles?: bookFile[] | [],
}
export interface AutomaticShutdown {
    status: boolean,
    select: string,
    dateStop: null | number,
}

export interface CounterState {
    playCard: PlayCard,
    startProgress: number,
    automaticShutdown: AutomaticShutdown,
    speed: string,
}

const initialState: CounterState = {
    playCard: {
        id: null,
        status: false,
        statusPlayer: true,
        audioUrl: '',
        listFile: [],
        nameBookFiles: [],
    },
    startProgress: 0,
    automaticShutdown: {
        status: false,
        select: "auto",
        dateStop: null
    },
    speed: '1'
};

export const slice = createSlice({
    name: 'player',
    initialState,
    reducers: {
        cleanPlayer(state) {
            state.playCard = {
                id: null,
                status: false,
                statusPlayer: true,
                audioUrl: '',
                listFile: [],
                nameBookFiles: [],
            }
        },
        setPlayerCard(state, action: PayloadAction<PlayCard>) {
            let { status, ...obj } = action.payload;
            state.playCard = { ...obj, status: false, statusPlayer: true };
        },
        setStartProgress(state, action: PayloadAction<{ progress: number }>) {
            state.startProgress = action.payload.progress;
        },
        currentPlayerStartOrPause(state, action: PayloadAction<boolean>) {
            state.playCard.status = action.payload;
        },
        nextTrackAction(state, action: PayloadAction<string>) {
            state.playCard.audioUrl = action.payload;
        },
        updateAutomaticShut(state, action: PayloadAction<AutomaticShutdown>) {
            state.automaticShutdown = action.payload;
        },
        updateSpeed(state, action: PayloadAction<string>) {
            state.speed = action.payload;
        },
        updateStatusPlayer(state, action: PayloadAction<boolean>) {
            state.playCard.statusPlayer = action.payload
        }
    },
});

export const {
    setPlayerCard,
    currentPlayerStartOrPause,
    updateAutomaticShut,
    updateSpeed,
    nextTrackAction,
    updateStatusPlayer,
    setStartProgress,
    cleanPlayer
} = slice.actions;

export default slice.reducer;
