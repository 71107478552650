import { FC, MouseEventHandler, useState } from "react";

import { Avatar } from "../avatar";
import { FieldInput } from "../fieldInput";
import { FieldInputData } from "../fieldInputData";
import { FieldInputEmail } from "../fieldInputEmail";
import { Button } from "../button";

import {
    validFormProfile,
    validData,
    validEmail,
    validNameRegular,
    dopAllValidEmail
} from "../../units/valids";

import { FormProfileInterface } from "./forms";

export interface ProfileProps {
    formProfile: FormProfileInterface
    setFormProfile: Function,
    sendProfile: Function,
    missProfile: MouseEventHandler<HTMLButtonElement>,
    loadBtn: boolean
};

export interface ErrorDopField {
    firstName: boolean,
    lastName: boolean,
    data: boolean,
    email: boolean,
}

export const Profile: FC<ProfileProps> = ({
    formProfile,
    setFormProfile,
    loadBtn,
    sendProfile = () => { },
    missProfile = () => { },
}) => {
    const [dopErrorFiedls, setDopErrorFiedls] = useState<ErrorDopField>({ firstName: true, lastName: true, data: true, email: true });
    const [errorEmail, setErrorEmail] = useState("Будь ласка, введіть коректну електронну адресу!");

    const updateSet = ({ name, value }: { name: string, value: string | boolean }) => {
        setFormProfile((prev: object) => ({ ...prev, ...{ [name]: value } }));
    }

    const sendHandleClick = () => {
        let objErrorFields: ErrorDopField = {
            firstName: validNameRegular(formProfile.firstName),
            lastName: validNameRegular(formProfile.lastName),
            data: validData(formProfile.data),
            email: validEmail(formProfile.email)
        }

        dopAllValidEmail({ value: formProfile.email || "", error: !dopErrorFiedls.email, set: handleErrorEmail });

        setDopErrorFiedls(prev => ({
            ...prev,
            ...objErrorFields
        }));

        let statusError = objErrorFields.firstName &&
            objErrorFields.lastName &&
            objErrorFields.data &&
            objErrorFields.email;

        if (statusError)
            sendProfile(setErrorEmail);
    }

    const handleErrorEmail = (value: string) => {
        setDopErrorFiedls(prev => ({
            ...prev,
            ...{ email: false }
        }));

        setErrorEmail(value);
    }

    return (
        <div className="aform__profile fprof">
            <div className="fprof__avat">
                <Avatar src={formProfile.avatar} />
                <p className="fprof__d text_center">Заповніть Ваші персональні дані</p>
            </div>
            <div className="fprof__fields fprof__gap fprof__gap_12">
                <FieldInput
                    name="FirstName"
                    placeholder="Імʼя"
                    required={true}
                    errorMessage={!dopErrorFiedls.firstName ? "Формат введення імені не має містити в собі такі елементи !,#,$,% а також не латиницю" : ""}
                    value={formProfile.firstName}
                    handleChange={(value: string) => updateSet({ name: 'firstName', value })}
                />
                <FieldInput
                    name="lastName"
                    placeholder="Прізвище"
                    required={true}
                    errorMessage={!dopErrorFiedls.lastName ? "Формат введення прізвища не має містити в собі такі елементи !,#,$,% а також не латиницю" : ""}
                    value={formProfile.lastName}
                    handleChange={(value: string) => updateSet({ name: 'lastName', value })}
                />
                <FieldInputData
                    name="data"
                    placeholder="Дата народження"
                    valueIn={formProfile.data}
                    errorMessage={`${!dopErrorFiedls.data ? "Формат введення дати народження дд.мм.рр" : ""}`}
                    handleChange={(value: string) => updateSet({ name: 'data', value })}
                />
                <FieldInputEmail
                    name="email"
                    placeholder="Електронна пошта"
                    errorMessage={`${!dopErrorFiedls.email ? errorEmail : ""}`}
                    value={formProfile.email}
                    handleChange={(value: string) => updateSet({ name: 'email', value })}
                />
            </div>
            <div className="fprof__btn">
                <div>
                    <Button label="Готово" load={loadBtn} disabled={!validFormProfile(formProfile)} handleClick={sendHandleClick} />
                </div>
                {/* <div>
                    <Button label="Пропустити" type='b' handleClick={missProfile} />
                </div> */}
            </div>
        </div>
    )
}