import { FC } from "react";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import routers from "../constants/routers.json";

export interface CheckedTermsProps {
    checked: boolean,
    handleChange?: Function,
    value?: string,
    val: string | number | boolean
};

export const CheckedTerms: FC<CheckedTermsProps> = ({
    checked = false,
    handleChange = () => { },
    value = '',
    val
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    let key = '';

    if (!!searchParams.get('key'))
        key = `?key=${searchParams.get('key')}`;

    return (
        <div className="checked-terms">
            <div>
                <label className="check">
                    <input
                        type="checkbox"
                        checked={checked}
                        value={value}
                        name="checked terms"
                        onChange={(e: React.FormEvent<HTMLInputElement>): void => handleChange(val)}
                    />
                    <span></span>
                </label>
            </div>
            <div className="checked-terms__text">
                <p>Створюючи обліковий запис, ви погоджуєтеся з нашими <Link to={`${routers.termsAndConditions.link}${key}`}>{routers.termsAndConditions.name}</Link>, а також <Link to={`${routers.privacyPolicy.link}${key}`}>{routers.privacyPolicy.name}</Link> </p>
            </div>
        </div>
    )
}