import { FC, useState } from "react";

import { IconSprite } from "./iconSprite";

interface CookiesProps { };

export const Cookies: FC<CookiesProps> = () => {
    const [show, setShow] = useState<boolean>(true);

    const nandleSend = () => {
        setShow(false);
        localStorage.setItem('cookies', '1');
    }

    return (
        show ?
            <>
            <div className="cookie_opas"></div>
                <div className="cookie">
                    <button className="cookie__close-btn" onClick={() => setShow(false)}><IconSprite id="icon_close_cookie" /></button>
                    <div className="cookie__title">Цей сайт використовує файли cookie</div>
                    <div className="cookie__des">Ми використовуємо файли cookie, щоб персоналізувати вміст і рекламу, а також аналізувати наш трафік. Ми також надаємо інформацію про те, як ви використовуєте веб-сайт, нашим партнерам із соціальних мереж, реклами та аналітики. Ви можете дізнатися більше про файли cookie, які ми використовуємо, з нашого Повідомлення про конфіденційність</div>
                    <div className="cookie__btn_w">
                        <button className="cookie__btn" onClick={nandleSend}>Прийняти</button>
                    </div>
                </div>
            </> : <></>
    )
}