import { combineReducers } from '@reduxjs/toolkit';

import theme from './theme';
import session from './session';
import modals from './modals';
import player from './player';
import profile from "./profile";
import notification from "./notifications";
import books from "./books";
import book from "./book";
import filters from "./filters";
import dataPages from "./dataPages";
import faqs from './faqs';

export default combineReducers({
    theme,
    session,
    modals,
    player,
    profile,
    notification,
    books,
    book,
    filters,
    dataPages,
    faqs
})