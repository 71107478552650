import { FC } from "react";
import { Link } from "react-router-dom";

import { CardPanel } from "./cardPanel";
import { IconSprite } from "./iconSprite";
import { RecentPropgress } from "./recentProgress";

import { useAppSelector, useAppDispatch } from "../hooks/state";
import { setPlayerCard, currentPlayerStartOrPause, setStartProgress } from "../stores/slices/player";
import { setModalOpen, setModalIdCardstate } from "../stores/slices/modals";
import { bookFile } from "../stores/slices/books";

import { timeCard } from "../units/timeOut";

import routers from "../constants/routers.json";

export interface CardRecentProps {
    id: number | string | null,
    img: string,
    file: any,
    title?: string,
    des?: string,
    date?: string,
    favorite?: boolean,
    isPhone?: boolean,
    author?: string,
    totalDuration: number,
    stopDuration: number,
    progress: number,
    fileList: bookFile[],
    nameBookFiles: bookFile[],
    startPropgress?: number,
    lastFile: string,
    handleFavorite: Function
};

export const CardRecent: FC<CardRecentProps> = ({
    id = null,
    img,
    file,
    title = '',
    des = '',
    date = '',
    favorite = false,
    isPhone = false,
    author = '',
    totalDuration,
    stopDuration,
    progress,
    fileList,
    nameBookFiles,
    startPropgress = 0,
    lastFile,
    handleFavorite
}) => {
    const dispatch = useAppDispatch();
    const { playCard } = useAppSelector(state => state.player);

    const handlePlay = () => {
        dispatch(currentPlayerStartOrPause(false));
        setTimeout(() => {
            dispatch(setPlayerCard({ id, status: true, audioUrl: file, listFile: fileList, nameBookFiles: nameBookFiles }));
            setTimeout(() => {
                dispatch(currentPlayerStartOrPause(true));
                setTimeout(() => dispatch(setStartProgress({ progress: startPropgress })), 200);
            }, 100);
        }, 100);
    }
    const handlePause = () => dispatch(currentPlayerStartOrPause(false));
    const openModalDown = () => {
        dispatch(setModalOpen('format'));
        dispatch(setModalIdCardstate(Number(id) || 0));
    };

    return (
        <div className="cardrecent">
            <CardPanel
                id={id}
                routersLink={routers.audioBook_I.link}
                handlePause={handlePause}
                handlePlay={handlePlay}
                openModalDown={openModalDown}
                playCard={playCard}
                favorite={favorite}
                img={img}
                handleFavorite={() => handleFavorite(id, favorite)}
            />
            <div className="cardrecent__main">
                <div>
                    <div className="cardrecent__title">
                        {
                            isPhone && (
                                <>
                                    {(title?.length > 0) && <Link to={`${routers.audioBook_I.link}${id}`} className="aycard__title font_w_500">{title}</Link>}
                                    {(author?.length > 0) && <p className="aycard__des font_w_300">{author}</p>}
                                </>
                            )
                        }
                        {
                            !isPhone && (
                                <Link to={`${routers.audioBook_I.link}${id}`}><span>{title}</span> / {author}</Link>
                            )
                        }
                    </div>
                    {
                        isPhone && (
                            <>
                                <div className="cardrecent__items">
                                    <div className="cardrecent__it">
                                        <IconSprite id="headphones_card" />
                                        <span>{date}</span>
                                    </div>
                                </div>
                                <div className="cardrecent__des">
                                    <p dangerouslySetInnerHTML={{
                                        __html: des
                                    }}></p>
                                </div>
                            </>
                        )
                    }
                    {
                        !isPhone && (
                            <>
                                <div className="cardrecent__des">
                                    <p dangerouslySetInnerHTML={{
                                        __html: des
                                    }}></p>
                                </div>
                                <div className="cardrecent__items">
                                    <div className="cardrecent__it">
                                        <IconSprite id="headphones_card" />
                                        <span>{date}</span>
                                    </div>
                                </div>
                            </>
                        )
                    }

                </div>
                <div>
                    <RecentPropgress
                        allTime={timeCard(totalDuration)}
                        endTime={timeCard(stopDuration)}
                        progress={(Math.floor(progress) <= 100) ? Math.floor(progress) : 100}
                    />
                </div>
            </div>
        </div>
    )
}