import { createAsyncThunk } from '@reduxjs/toolkit';

import api from "../../api";

import { AppDispatch, RootState } from "../index";
import { setCloseModalProfile, setWindowForm } from "../slices/theme";
import { setAuth } from "../slices/session";
import { setProfileInfo, cleanForm } from "../slices/profile";
import { addNotification } from "../slices/notifications";
import { removeCookeToken, setCookeToken, getCookeToken } from "../../units/session";
import { converDataProfile } from "../../units/converDataProfile";
import { isAvaliabilityProfile } from "../../units/avaliabilityProfile";

export const exitProfile = (dispatch: any) => {
    new Promise(async (resolve) => {
        let res = await api.authApi.logout();

        if (!!res?.success) resolve(true);
    }).then(() => {
        removeCookeToken();
        dispatch(setCloseModalProfile());
        dispatch(setAuth(false));
        dispatch(cleanForm());
        api.api.setToken('');
    })
}

export const contFindOrCreate = async ({ phone, dispatch, setLoad, registr = true }: { phone: string, dispatch: any, setLoad: Function, registr?: boolean }) => {
    setLoad(true);

    const res = await api.authApi.findOrCreate({ phone });

    if (res?.data?.id) {
        dispatch(setWindowForm('code'));
        dispatch(setProfileInfo(converDataProfile(res.data)));
    }

    setLoad(false);
}

export const resendCode = async (
    { userId, dispatch, isOneRepeat, setRepeatCode, setIsOneRepeat }:
        { userId: number | string, dispatch: any, setRepeatCode: Function, isOneRepeat: boolean, setIsOneRepeat: Function }
) => {
    const res = await api.authApi.resendCode({ user_id: userId });

    if (res?.success) {
        dispatch(addNotification({ message: res.message }));
        setRepeatCode(false);

        setIsOneRepeat(false);
        return;
    }

    dispatch(addNotification({ message: 'Помилка' }));
}

export const contSessAuthCode = createAsyncThunk<
    { dataProfile: boolean, auth: boolean },
    { code: string, setLoadBtn: Function, setErrorCode: Function },
    {
        dispatch: AppDispatch, state: RootState
    }
>('sessions/contSessAuthCode',
    async ({ code, setLoadBtn, setErrorCode }, { dispatch, getState }) => {
        setLoadBtn(true);

        const { profile } = getState();
        let isProfile = false;
        let isAuth = false;

        const res = await api.authApi.login({ phone: profile.data.phone, code });

        if (res?.data?.id && res?.data?.token) {
            setCookeToken(res?.data?.token);
            api.api.setToken(res?.data?.token);
            isAuth = true;
            isProfile = isAvaliabilityProfile(converDataProfile(res.data));

            if (!isProfile) dispatch(setWindowForm('profile'));

            setErrorCode(false);
        }

        if (!res?.success) {
            setErrorCode(true);
        }

        setLoadBtn(false);

        return {
            dataProfile: isProfile,
            auth: isAuth
        }
    }
);

export const sessionAuth = createAsyncThunk<
    { dataProfile: boolean, auth: boolean },
    undefined,
    { dispatch: AppDispatch, state: RootState }
>('sessions/contSessionAuth',
    async (_, { dispatch, getState }) => {
        let isAuth = false;
        let isProfile = false;

        let localToken: string | undefined = getCookeToken();

        if (!localToken) {
            return {
                dataProfile: true,
                auth: isAuth
            };
        }

        api.api.setToken(localToken);

        const res = await api.ProfileApi.getDataProfile();

        if (res?.data?.id) {
            isAuth = true;
            let dataConverProfile = converDataProfile(res.data);
            dispatch(setProfileInfo(dataConverProfile));
            
            isProfile = isAvaliabilityProfile(converDataProfile(res.data));
            if (!isProfile) dispatch(setWindowForm('profile'));
        }

        return {
            dataProfile: isProfile,
            auth: isAuth
        };
    }
)