import { FC, ChangeEvent, KeyboardEventHandler } from "react";

export interface FieldInputProps {
    placeholder?: string,
    name?: string,
    errorMessage?: string,
    error?: boolean,
    value?: string,
    handleChange?: Function,
    handleFocus?: Function,
    required?: boolean,
    requireText?: string,
    refe?: any,
    disabled?: boolean,
    onEnter?: Function,
};

export const FieldInput: FC<FieldInputProps> = ({
    name = '',
    placeholder = '',
    errorMessage = '',
    error = false,
    value,
    required = false,
    requireText = '*поле обовʼязкове для введення',
    handleChange = () => { },
    handleFocus = () => { },
    onEnter = () => { },
    refe,
    disabled = false
}) => {

    return (
        <div className="field-wr">
            <input
                ref={(refs) => (typeof refe == "object") ? (refe.current = refs) : undefined}
                className={`field-input field-input_phone ${!!((errorMessage.length > 0) || error) ? "error" : ""}`}
                name={name}
                placeholder={placeholder}
                value={value}
                onInput={(e: ChangeEvent<HTMLInputElement>): void => handleChange(e.target.value)}
                onFocus={(e: ChangeEvent<HTMLInputElement>): void => handleFocus(e)}
                onKeyUp={(e: any) => onEnter(e)}
                disabled={disabled}
            />
            {
                !!(errorMessage.length > 0) && <div className="field-message-error">{errorMessage}</div>
            }
            {
                (required && errorMessage.length === 0) && <div className={`field-message_in ${error ? "error_message_in" : ""}`}>{requireText}</div>
            }
        </div>
    )
}