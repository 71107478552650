import Base from "./base";

export default class AuthApi extends Base {
    getAppTerm() {
        return this.api.get({ url: "show-app-term/1" });
    }
    getAppInfo() {
        return this.api.get({ url: "get-app-info" });
    }
    getPolicy() {
        return this.api.get({ url: "show-app-term/2" });
    }
}