import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FormType = 'up' | 'code' | 'login' | 'profile'

export interface CounterState {
    phone: boolean,
    bgModal: boolean,
    showModalProfile: boolean,
    showMobMenu: boolean,
    windowForm: FormType // up, code, login, profile | default up 
}

const initialState: CounterState = {
    phone: false,
    showMobMenu: false,
    bgModal: false,
    showModalProfile: false,
    windowForm: 'up'
};

export const slice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setPhone(state) {
            if (window.innerWidth <= 767) {
                state.phone = true;
            } else state.phone = false;
        },
        setBgModalOpen(state) {
            state.bgModal = true;
        },
        setBgModalClose(state) {
            state.bgModal = false;
        },
        setShowModalProfile(state) {
            state.bgModal = true;
            state.showModalProfile = true;
        },
        setCloseModalProfile(state) {
            state.bgModal = false;
            state.showModalProfile = false;
            state.windowForm = 'up';
        },
        setCloseModalAll(state) {
            state.bgModal = false;
            state.showModalProfile = false;
        },
        setWindowForm(state, action: PayloadAction<FormType>) {
            state.windowForm = action.payload;
        },
        setOpenMobMenu(state) {
            state.showMobMenu = true;
        },
        setCloseMobMenu(state) {
            state.showMobMenu = false;
        }
    }
});

export const {
    setPhone,
    setBgModalOpen,
    setBgModalClose,
    setShowModalProfile,
    setCloseModalProfile,
    setWindowForm,
    setCloseModalAll,
    setOpenMobMenu,
    setCloseMobMenu
} = slice.actions;

export default slice.reducer;
