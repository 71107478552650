import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppDispatch, RootState } from "../index";

import api from "../../api";

import { addNotification } from "../slices/notifications";
import { setCloseModal } from "../slices/modals";
import { setLoadCards, setLoadCardsFavorites, setLoadCardsLast } from "../slices/books";
import { getFilterGenres, getFilterAuthors, getFilterCollections } from "../controlers/filters";

export const addBooks = createAsyncThunk<
    {},
    { key: string, setError?: Function, calback?: Function },
    { dispatch: AppDispatch, state: RootState }
>('books/addBooks',
    async ({ key, setError = () => { }, calback = () => { } }, { dispatch, getState }) => {
        const res = await api.booksApi.addBook({ key: key });

        if (!res?.success) {
            let mes = res?.message ? res.message : 'Код не вірний';
            setError({ status: true, message: mes });
            dispatch(addNotification({ message: mes }));
            return {};
        }

        if (!!res?.success) {
            setError({
                status: false,
                message: ''
            });
            dispatch(addNotification({ message: 'Книгу додано!', icon: 'icon_notif_book' }));
            dispatch(setCloseModal());
            dispatch(getAllBooks({}));
            dispatch(getFilterGenres());
            dispatch(getFilterAuthors());
            dispatch(getFilterCollections());
            calback();
        }

        return {}
    }
)

export const getAllBooks = createAsyncThunk<
    {},
    { filter?: object, loade?: boolean },
    { dispatch: AppDispatch, state: RootState }
>('books/getActiveAllBooks',
    async ({ filter = {}, loade = true }, { dispatch, getState }) => {
        if (loade)
            dispatch(setLoadCards());

        const res = await api.booksApi.getAllActiveBooks(filter);

        return {
            data: res?.data || null,
            isLoad: loade
        };
    }
)

export const getAllBooksFavorites = createAsyncThunk<
    {},
    { filter?: object, loade?: boolean },
    { dispatch: AppDispatch, state: RootState }
>('books/getAllBooksFavorites',
    async ({ filter = {}, loade = true }, { dispatch, getState }) => {
        if (loade)
            dispatch(setLoadCardsFavorites());

        const res = await api.booksApi.getAllFavoriteBooks(filter);

        return {
            data: res?.data || null,
            isLoad: loade
        };
    }
)

export const getAllLastBooks = createAsyncThunk<
    {},
    { filter?: object, loade?: boolean },
    { dispatch: AppDispatch, state: RootState }
>('books/getAllLastBooks',
    async ({ filter = {}, loade = true }, { dispatch, getState }) => {
        if (loade)
            dispatch(setLoadCardsLast());

        const res = await api.booksApi.getAllLastBooks(filter);

        return {
            data: res?.data || null,
            isLoad: loade
        };
    }
)


