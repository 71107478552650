import Base from "./base";

export default class AuthApi extends Base {
    getDataProfile() {
        return this.api.get({ url: "auth-user" });
    }
    updateDataProfile(id: string | number | null, data: object) {
        return this.api.put({ url: `update-user/${id}`, payload: data });
    }
    updateDataProfileAvatar(id: string | number | null, data: object) {
        return this.api.post({ url: `save-user-avatar/${id}`, payload: data, type: 'formData' });
    }
}