import { FC, useEffect, useRef, useState } from "react";
import { Route, Routes } from "react-router-dom";

import { AppRouter } from "./appRouter";

import { HomePage } from "./pages/home";
import { RecentActivityPage } from "./pages/recentActivity";
import { FavoritesPage } from "./pages/favorites";
import { AudioBookPage } from "./pages/audioBook";
import { EditProfilePage } from "./pages/editProfile";
import { TermsAndConditionsPage } from "./pages/termsAndConditions";
import { PrivacyPolicy } from "./pages/privacyPolicy";

import { ModalLayout } from "./components/modals/modalLayout";
import { Notifications } from "./components/notifications";
import { Loadpage } from "./components/loadpage";

import { useAppDispatch, useAppSelector } from "hooks/state";
import { setPhone } from "./stores/slices/theme";
import { updateAutomaticShut, currentPlayerStartOrPause } from "./stores/slices/player";
import { getFilterGenres, getFilterAuthors, getFilterCollections } from "./stores/controlers/filters";

import { dateCleanStop } from "./units/data";

import dataRouters from "./constants/routers.json";

export interface IApplicationProps { };

export const App: FC<IApplicationProps> = () => {
  const [first, setFirst] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { player: { automaticShutdown }, session, books } = useAppSelector(state => state);
  const refIdInter = useRef<any>();

  useEffect(() => {
    window.addEventListener('resize', function () {
      dispatch(setPhone());
    });
    dispatch(setPhone());
  }, []);

  useEffect(() => {
    if (!!refIdInter.current)
      clearInterval(refIdInter.current);

    if (automaticShutdown.status)
      refIdInter.current = setInterval(() => {
        if (automaticShutdown.dateStop === null) return;

        let isStop = dateCleanStop(automaticShutdown.dateStop);

        if (isStop) return;

        dispatch(updateAutomaticShut({
          status: false,
          select: "auto",
          dateStop: null
        }));

        dispatch(currentPlayerStartOrPause(false));
      }, 1e4);
  }, [automaticShutdown.status]);

  useEffect(() => {
    if (!first) setFirst(true);

    if (first && session.auth) {
      dispatch(getFilterGenres());
      dispatch(getFilterAuthors());
      dispatch(getFilterCollections());
    }
  }, [session.auth, first]);

  return (
    <>
      <Routes>
        <Route path={dataRouters.home.link} element={
          <AppRouter
            component={HomePage}
            isHome={true}
            isPlayer={true}
          />
        } />

        <Route path={dataRouters.recentActivity.link} element={
          <AppRouter
            component={RecentActivityPage}
            isPlayer={true}
          />
        } />

        <Route path={dataRouters.favorites.link} element={
          <AppRouter
            component={FavoritesPage}
            isPlayer={true}
          />
        } />

        <Route path={dataRouters.audioBook.link} element={
          <AppRouter
            component={AudioBookPage}
            isPlayer={true}
          />
        } />
        <Route path={dataRouters.editProfile.link} element={
          <AppRouter
            component={EditProfilePage}
            isPlayer={true}
          />
        } />

        <Route path={dataRouters.termsAndConditions.link} element={
          <AppRouter
            isPublick={true}
            component={TermsAndConditionsPage}
            isPlayer={true}
          />
        } />

        <Route path={dataRouters.privacyPolicy.link} element={
          <AppRouter
            isPublick={true}
            component={PrivacyPolicy}
            isPlayer={true}
          />
        } />

        <Route path="*" element={
          <AppRouter
            component={HomePage}
            isHome={true}
            isPlayer={true}
          />
        } />
      </Routes>
      <ModalLayout />
      <Notifications />
      {
        session.isAuthLoad && (
          <Loadpage />
        )
      }
    </>
  )
}