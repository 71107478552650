interface ItemList {
    id: string | number,
    name: string,
    value: string
};

export const activeItem = (list: ItemList[], value: string): string => {
    let item = list.find(el => el.value === value);

    if (item?.name) return item.name;

    return '';
}