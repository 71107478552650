import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { profileEdit, profileEditAvatarBase } from "../controlers/profile";

export interface ProfileInt {
    id: null | string | number,
    firstName: string | null,
    lastName: string | null,
    phone: string | null,
    data: string | null,
    email: string | null,
    urlAvatar: string | null,
    avatarBase64: string | null,
}

export interface CounterState {
    data: ProfileInt,
    loadBtn: boolean,
}

const initialState: CounterState = {
    data: {
        id: null,
        firstName: '',
        lastName: '',
        phone: '',
        data: '',
        email: '',
        avatarBase64: '',
        urlAvatar: '',
    },
    loadBtn: false,
};

export const slice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        updateField(state, action: PayloadAction<{ name: string, value: string }>) {
            const { name, value } = action.payload;

            return ({
                ...state,
                data: { ...state.data, ...{ [name]: value } }
            });
        },
        setProfileInfo(state, action: PayloadAction<ProfileInt>) {
            return {
                ...state,
                data: { ...state.data, ...action.payload }
            }
        },
        cleanForm(state) {
            state = {
                data: {
                    id: null,
                    firstName: '',
                    lastName: '',
                    phone: '',
                    data: '',
                    email: '',
                    avatarBase64: '',
                    urlAvatar: '',
                },
                loadBtn: false,
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(profileEdit.pending, (state, action) => {
                state.loadBtn = true;
            })
            .addCase(profileEdit.fulfilled, (state, action: PayloadAction<ProfileInt>) => {
                if (action.payload?.id) {
                    state.data = { ...state.data, ...action.payload }
                }
                state.loadBtn = false;
            })
            .addCase(profileEditAvatarBase.fulfilled, (state, action: PayloadAction<ProfileInt>) => {
                if (action.payload?.id) {
                    state.data = { ...state.data, ...action.payload }
                }
            })
    },
});

export const { updateField, setProfileInfo, cleanForm } = slice.actions;

export default slice.reducer;
