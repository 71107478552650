import { FC, useState, useRef, ChangeEvent } from "react";

import { ModalAvatar } from "./modals/avatar";

import { useAppDispatch } from "hooks/state";
import { profileEditAvatarBase } from '../stores/controlers/profile';

export interface AvatarProps {
    src: string
};

export const Avatar: FC<AvatarProps> = ({
    src = '',
}) => {
    const dispatch = useAppDispatch();
    const [showModal, setShowModal] = useState<boolean>(false);
    const handleClose = () => setShowModal(false);

    const handleGenerat = (res: string) => {
        dispatch(profileEditAvatarBase({ avatarCode: res }));
    }

    return (
        <div className="ava">
            <div className="avat">
                <label className="avat__lab" onClick={() => setShowModal(true)}>
                    {
                        (src.length > 0) && <img className="avat__img" src={src} alt="Фото профіля" />
                    }
                    <span className="avat__t">{(src.length > 0) ? 'Змінити фото' : 'Додати фото'}</span>
                </label>
            </div>

            {
                showModal && (
                    <ModalAvatar
                        handleClose={handleClose}
                        handleGenerat={handleGenerat}
                        src={src}
                    />
                )
            }
        </div>
    )
}