import { FC, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import { BookInfo } from "../components/bookInfo";
import { BookSection } from "../components/bookSection";
import { Faqs } from "../components/faq";

import { useAppDispatch, useAppSelector } from "hooks/state";
import { setModalOpen, setModalIdCardstate } from "../stores/slices/modals";
import { setPlayerCard, currentPlayerStartOrPause, setStartProgress } from "../stores/slices/player";
import { getBook } from "../stores/controlers/book";
import { favoriteSelectBook, downloadFile } from "../stores/controlers/book";
import { searchLastFile } from "../units/players";

export interface AudioBookProps { };

export const AudioBookPage: FC<AudioBookProps> = () => {
    const [first, setFirst] = useState<boolean>(false);
    const [loadDownItem, setLoadDownItem] = useState<string[]>([]);
    const dispatch = useAppDispatch();
    const { theme, book: { bookCard, loadGet }, player, faqs } = useAppSelector(state => state);
    let params = useParams();

    const openModalDownloadKolection = () => {
        dispatch(setModalOpen('colection'));
        dispatch(setModalIdCardstate(Number(bookCard?.id || 0)));
    };

    const handlePlay = () => {
        if (bookCard === null) return;

        dispatch(currentPlayerStartOrPause(false));

        if (!bookCard?.lastActivity?.file_name) {
            setTimeout(() => {
                dispatch(setPlayerCard({
                    id: bookCard.id,
                    status: true,
                    audioUrl: bookCard.urlBookFiles[0].name,
                    listFile: bookCard.urlBookFiles,
                    nameBookFiles: bookCard.nameBookFiles
                }));
                setTimeout(() => {
                    dispatch(currentPlayerStartOrPause(true));
                }, 100);
            }, 100);
        }

        if (!!bookCard?.lastActivity?.file_name) {

            setTimeout(() => {
                dispatch(setPlayerCard({
                    id: bookCard.id,
                    status: true,
                    audioUrl: searchLastFile(bookCard?.lastActivity.file_name, bookCard.urlBookFiles),
                    listFile: bookCard.urlBookFiles,
                    nameBookFiles: bookCard.nameBookFiles
                }));
                setTimeout(() => {
                    dispatch(currentPlayerStartOrPause(true));
                    setTimeout(() => dispatch(setStartProgress({ progress: bookCard?.lastActivity.track_percentage || 0 })), 200);
                }, 100);
            }, 100);
        }
    }

    const handleFavorite = () => {
        dispatch(favoriteSelectBook());
    }

    const handlePlayItem = ({ player, fileUrl }: { player: boolean, fileUrl: string }) => {
        if (bookCard === null) return;

        if (!player) {
            dispatch(currentPlayerStartOrPause(false));
            return;
        }

        let isLast = fileUrl.includes(bookCard?.lastActivity?.file_name || "");

        if (isLast) {
            dispatch(currentPlayerStartOrPause(false));
            setTimeout(() => {
                dispatch(setPlayerCard({
                    id: bookCard.id,
                    status: true,
                    audioUrl: fileUrl,
                    listFile: bookCard.urlBookFiles,
                    nameBookFiles: bookCard.nameBookFiles
                }));
                setTimeout(() => {
                    dispatch(currentPlayerStartOrPause(true));
                    setTimeout(() => dispatch(setStartProgress({ progress: bookCard?.lastActivity.track_percentage || 0 })), 200);
                }, 100);
            }, 100);
        }

        if (!isLast) {
            dispatch(currentPlayerStartOrPause(false));
            setTimeout(() => {
                dispatch(setPlayerCard({
                    id: bookCard.id,
                    status: true,
                    audioUrl: fileUrl,
                    listFile: bookCard.urlBookFiles,
                    nameBookFiles: bookCard.nameBookFiles
                }));
                setTimeout(() => {
                    dispatch(currentPlayerStartOrPause(true));
                }, 100);
            }, 100);
        }
    }

    const downFile = (fileName: string) => {
        dispatch(downloadFile({ id: Number(bookCard?.id || 0), fileName, setLoadDownItem }))
    }

    useEffect(() => {
        if (!first) setFirst(true);

        if (first && params?.id) {
            dispatch(getBook({ id: params.id }));
        }
    }, [first]);

    return (
        <div className="abook page-p-b">
            <div className="container">
                <div className="books">
                    {
                        loadGet && (
                            <div className="load-book">
                                <div className="load-book__top">
                                    <div className="load-book__top_left load-book_card"></div>
                                    <div className="load-book__top_right">
                                        <div className="load-book__top_right__top load-book_card"></div>
                                        <div className="load-book__top_right__bot load-book_card"></div>
                                    </div>
                                </div>
                                <div className="load-book__bot load-book_card"></div>
                            </div>
                        )
                    }
                    {
                        !loadGet && (
                            <>
                                <BookInfo
                                    handleDownload={openModalDownloadKolection}
                                    isPhone={theme.phone}
                                    description={bookCard?.description || ''}
                                    title={bookCard?.title || ''}
                                    author={bookCard?.author || ''}
                                    performer={bookCard?.voice_acting || ''}
                                    year={bookCard?.year || ''}
                                    fileType={bookCard?.isbn || ''}
                                    fileSize={bookCard?.size || ''}
                                    time={bookCard?.duration || 0}
                                    img={bookCard?.full_path_photo || ''}
                                    handlePlay={handlePlay}
                                    favorite={bookCard?.isFavorite || false}
                                    handleFavorite={() => handleFavorite()}
                                    islast={!!bookCard?.lastActivity.file_name}
                                />
                                <BookSection
                                    idBook={bookCard?.id || ''}
                                    nameBookFiles={bookCard?.nameBookFiles || []}
                                    urlBookFiles={bookCard?.urlBookFiles || []}
                                    isPhone={theme.phone}
                                    lastActive={bookCard?.lastActivity || undefined}
                                    player={player.playCard}
                                    handlePlayItem={handlePlayItem}
                                    downFile={downFile}
                                    loadDownItem={loadDownItem}
                                />
                            </>
                        )
                    }

                </div>
            </div>
            <Faqs
               title="Питання що часто задаються"
                subTitle=""
                data={faqs.book}
            />
        </div>
    )
}