import { FC } from "react";
import { Link } from "react-router-dom";

import { IconSprite } from "./iconSprite";

export interface PlayCard {
    id: string | number | null,
    status: boolean,
    audioUrl: string,
}

export interface CardPanel {
    id: number | string | null,
    img?: string,
    playCard: PlayCard,
    favorite?: boolean,
    routersLink: string,
    handlePause?: Function,
    handlePlay?: Function,
    openModalDown?: Function,
    handleFavorite?: Function,
};

export const CardPanel: FC<CardPanel> = ({
    id,
    handlePause = () => { },
    handlePlay = () => { },
    openModalDown = () => { },
    img = '',
    routersLink,
    playCard,
    favorite = false,
    handleFavorite = () => { }
}) => {
    return (
        <div className="aycard__panel">
            <Link to={`${routersLink}${id}`} className="aycard__panel_link-img">
                {
                    !!img && <img src={img} alt="" />
                }
            </Link>
            <div className="card-panel">
                <div>
                    <button className="card-panel_button" onClick={() => openModalDown()}>
                        <IconSprite id='icon_panel_card_down' />
                    </button>
                </div>
                <button className="card-panel_play" onClick={() => (playCard.status && (id == playCard.id)) ? handlePause() : handlePlay()}>
                    <IconSprite id={(playCard.status && (id == playCard.id)) ? "icon_panel_card_pause" : "icon_panel_card_play"} />
                </button>
                <div>
                    <button className="card-panel_button" onClick={() => handleFavorite(favorite)}>
                        <IconSprite id={favorite ? "icon_panel_card_heart_floodet" : "icon_panel_card_heart"} />
                    </button>
                </div>
            </div>
        </div>
    )
}