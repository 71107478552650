import { FC, MouseEventHandler } from "react";

import { IconSprite } from "./iconSprite";

export interface PaginationProps {
    activePage: number,
    handlePage: Function,
    pages?: number
}

export const Pagination: FC<PaginationProps> = ({
    activePage,
    handlePage,
    pages = 1
}) => {
    return (
        <div className="pagination">
            <div className="pagination_wr">
                <button className="pagination__btn" disabled={activePage === 1} onClick={() => handlePage(activePage - 1)}>
                    <IconSprite id="icon_btn_pagin_left" />
                </button>
                {
                    [...new Array(pages)].map((_, index) => (
                        <button key={index} className={`pagination__page ${(activePage === index + 1) ? "active" : " "}`} onClick={() => handlePage(index + 1)}>{index + 1}</button>
                    ))
                }
                <button className="pagination__btn" disabled={pages === activePage} onClick={() => handlePage(activePage + 1)}>
                    <IconSprite id="icon_btn_pagin_right" />
                </button>
            </div>
        </div>
    )
}