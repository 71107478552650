import { FC, useState, useEffect, useRef } from "react";

import { formaTime } from "../../units/formatTime";

export interface ReadOutProps {
    startTimeMin?: number,
    setThereTime: Function,
    isTime: boolean
};

export const ReadOut: FC<ReadOutProps> = ({
    startTimeMin = 3,
    setThereTime = () => { },
    isTime
}) => {
    const [time, setTime] = useState<number>(startTimeMin * 60);
    const refId = useRef<any>(null);

    useEffect(() => {
        clearTimeout(refId.current);

        if (time > 0) {
            refId.current = setTimeout(() => {
                setTime(prev => prev - 1);
            }, 1000);
        }

        if (time === 1) {
            setTime(0);
            setThereTime();
        }
    }, [time]);

    useEffect(() => {
        if (!isTime)
            setTime(startTimeMin * 60);
    }, [isTime]);

    return (
        <div className="readout text_center">
            <p className="readout__des">*код з смс на платформі діє {startTimeMin} хвилин з моменту отримання його на Ваш смартфон</p>
            <div className="readout__time">{formaTime(time)}</div>
        </div>
    )
}