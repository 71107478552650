import Base from "./base";

export default class AuthApi extends Base {
    findOrCreate(data: object) {
        return this.api.post({ url: "find-or-create-user", payload: data });
    }
    login(data: object) {
        return this.api.post({ url: "login", payload: data });
    }
    resendCode(data: object) {
        return this.api.post({ url: "resend-code", payload: data });
    }
    logout() {
        return this.api.get({ url: "logout" });
    }
}