import { FC } from "react";

import { Head } from "./head";
import { Forms } from "./forms";
import { Cookies } from "../cookies";

import { useAppSelector } from '../../hooks/state';

export interface AutorizationProps { };

export const Autorization: FC<AutorizationProps> = () => {
    const { theme: { windowForm }, profile, session: { isAuthLoad } } = useAppSelector(state => state)

    return (
        <div className={`auth ${isAuthLoad ? "blur" : ''}`}>
            <Head />
            <div className="auth__main">
                <Forms
                    windowForm={windowForm}
                    profile={profile}
                />
            </div>
            {
                ((windowForm === 'profile') && !localStorage.getItem('cookies')) && (
                    <Cookies />
                )
            }
        </div>
    )
}