import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    getAppTerm,
    getAppInfo,
    getPolicy
} from "../controlers/dataPages";

interface dataPagesIntr {
    terms: {
        description: string,
        title: string
    },
    apInfo: {
        description: string,
        title: string
    },
    privacy: {
        description: string,
        title: string
    }
}

const initialState: dataPagesIntr = {
    terms: {
        description: '',
        title: ''
    },
    apInfo: {
        description: '',
        title: ''
    },
    privacy: {
        description: '',
        title: ''
    }
}

export const slice = createSlice({
    name: "dataPages",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAppTerm.fulfilled, (state, action: PayloadAction<any>) => {
                state.terms = action.payload || { description: '', title: '' };
            })
            .addCase(getAppInfo.fulfilled, (state, action: PayloadAction<any>) => {
                state.apInfo = action.payload || { description: '', title: '' };
            })
            .addCase(getPolicy.fulfilled, (state, action: PayloadAction<any>) => {
                state.privacy = action.payload || { description: '', title: '' };
            })
    }
});

export default slice.reducer;