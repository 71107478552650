import { FC } from "react";

export interface RecentPropgressProps {
    allTime: string,
    endTime: string,
    progress: number
};

export const RecentPropgress: FC<RecentPropgressProps> = ({
    allTime,
    endTime,
    progress
}) => {
    return (
        <div className="recent-progress">
            <div className="recent-progress__time">{allTime}</div>
            <div className="recent-progress__wr">
                <div className="propg">
                    <div className="propg_active" style={{ width: `${progress}%` }}>
                        <span>{progress}%</span>
                    </div>
                </div>
            </div>
            <div className="recent-progress__time">{endTime}</div>
        </div>
    )
}