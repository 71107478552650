import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppDispatch, RootState } from "../index";

import api from "../../api";

import { addNotification } from "../slices/notifications";
import { setCloseModal } from "../slices/modals";

export const getFaqs = createAsyncThunk<
    {},
    undefined,
    { dispatch: AppDispatch, state: RootState }
>('fags/getFaqs',
    async (_, { dispatch, getState }) => {
        let ogj = {
            home: [],
            recent: [],
            favorite: [],
            book: [],
        }

        // home
        const resHome = await api.faqsApi.getFaqs(1);

        if (!!resHome?.data?.faqs)
            ogj.home = resHome?.data.faqs || [];

        // recent
        const resRecent = await api.faqsApi.getFaqs(2);

        if (!!resRecent?.data.faqs)
            ogj.recent = resRecent?.data.faqs || [];

        // favorit
        const resFavorit = await api.faqsApi.getFaqs(3);

        if (!!resFavorit?.data.faqs)
            ogj.favorite = resFavorit?.data.faqs || [];

        // book
        const resBook = await api.faqsApi.getFaqs(4);

        if (!!resBook?.data.faqs)
            ogj.book = resBook?.data.faqs || [];

        return ogj;
    }
)

export const sendContactForm = async ({
    obj,
    setLoad,
    dispatch,
    setErrorServer,
}: {
    obj: object,
    setLoad: Function,
    dispatch: any,
    setErrorServer: Function,
}) => {
    setLoad(true);

    const res = await api.faqsApi.sendContactForm(obj);

    if (!!res.success) {
        dispatch(addNotification({ message: 'Звернення надіслано.' }));
        setErrorServer(false);
        dispatch(setCloseModal());
    }

    if (!res.success) {
        setErrorServer(true);
    }

    setLoad(false);
}

