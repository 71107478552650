import { ProfileInt } from "../stores/slices/profile";

export const isAvaliabilityProfile = (obj: ProfileInt): boolean => {
    return (
        !!obj.firstName?.length &&
        !!obj.lastName?.length
    );

    // return (
    //     !!obj.firstName?.length &&
    //     !!obj.lastName?.length &&
    //     !!obj.phone?.length &&
    //     !!obj.data?.length &&
    //     !!obj.email?.length &&
    //     (!!obj.urlAvatar?.length || !!obj.avatarBase64?.length)
    // );
}