import { FC } from "react"

import { IconSprite } from "./iconSprite";

export interface NoListProps {
    title:string,
    text: string
};

export const NoList: FC<NoListProps> = ({
    title,
    text
}) => {
    return (
        <div className="nolist">
            <span className="nolist__bg">
                <IconSprite id='bg_no_list' />
            </span>
            <div className="nolist__cont text_center">
                <div className="nolist__title">{title}</div>
                <p className="nolist__des">{text}</p>
            </div>
        </div>
    )
}
