import Base from "./base";

export default class AuthApi extends Base {
    getFilterGnres() {
        return this.api.get({ url: `show-books-genres` });
    }
    getFilterAuthors() {
        return this.api.get({ url: `show-books-authors` });
    }
    getFilterCollections() {
        return this.api.get({ url: `show-books-collections` });
    }
}