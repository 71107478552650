import { FC } from "react";

import { IconSprite } from "../iconSprite";
import { CodeBook } from "./codeBook";
import { ModalDownload } from "./download";
import { ModalExlusion } from "./exclusion";
import { ModalSpeed } from "./speed";
import { ModalDownloadColection } from "./downloadColection";
import { ModalFaq } from "./ModalFaq";

import { useAppDispatch, useAppSelector } from "../../hooks/state";
import { setCloseModal } from "../../stores/slices/modals";

export interface ModalLayoutProps { };

export const ModalLayout: FC<ModalLayoutProps> = () => {
    const dispatch = useAppDispatch();
    const { show, modal } = useAppSelector(state => state.modals);

    const handleClose = () => dispatch((setCloseModal()));

    return (
        <>
            {
                show && (
                    <div className={`hystmodal`}>
                        <div className="hystmodal__wrap">
                            <div className="hystmodal__op" onClick={handleClose}></div>
                            <div className="hystmodal__window">
                                <button className="hystmodal__close" onClick={handleClose}>
                                    <IconSprite id='close_modal' />
                                </button>
                                <div className="hystmodal__content">
                                    {(modal === 'code') && <CodeBook handleClose={handleClose} />}
                                    {(modal === 'format') && <ModalDownload />}
                                    {(modal === 'time') && <ModalExlusion />}
                                    {(modal === 'speed') && <ModalSpeed />}
                                    {(modal === 'colection') && <ModalDownloadColection />}
                                    {(modal === 'faq') && <ModalFaq />}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}