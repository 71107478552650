import { FC } from "react";

import { IconSprite } from "../iconSprite";
import { Link } from "react-router-dom";

import { Button } from "../button";

import { ProfileInt } from "../../stores/slices/profile";

import routers from "../../constants/routers.json";

export interface ProfileProps {
    auth?: boolean,
    src?: string,
    firstNameSymbol: string,
    openModal: Function,
    closeModal: Function,
    handleExit: Function,
    show: boolean,
    isPhone: boolean,
    profile: ProfileInt,
    handleOpenFaq:Function
};

export const Profile: FC<ProfileProps> = ({
    auth,
    src = '',
    firstNameSymbol = '',
    openModal,
    closeModal,
    handleOpenFaq,
    handleExit,
    show,
    isPhone,
    profile
}) => {
    return (
        <div className={`h-profile ${auth ? "" : "n_poin"}`}>
            {
                auth && (
                    <>
                        {
                            !isPhone && (
                                <div className="h-profile__avat" onClick={() => !isPhone && (show ? closeModal() : openModal())}>
                                    {
                                        !!(src.length > 0) && <img src={src} alt="" />
                                    }
                                    {
                                        !(src.length > 0) && <span>{firstNameSymbol}</span>
                                    }
                                </div>
                            )
                        }
                        {
                            isPhone && (
                                <Link to="/edit-profile" className="h-profile__avat">
                                    {
                                        !!(src.length > 0) && <img src={src} alt="" />
                                    }
                                    {
                                        !(src.length > 0) && <span>{firstNameSymbol}</span>
                                    }
                                </Link>
                            )
                        }
                    </>
                )
            }
            {
                (auth && !isPhone) && (
                    <div className={`h-profile__main ${show ? "open" : ""}`}>
                        <div className="h-profile__content">
                            <div className="h-profile__wr-ava">
                                <div className="h-profile__ava">
                                    {
                                        !!(src.length > 0) && <img src={src} alt="" />
                                    }
                                    {
                                        !(src.length > 0) && <span>{firstNameSymbol}</span>
                                    }
                                </div>
                            </div>
                            <Link to={routers.editProfile.link} className="h-profile__edit" onClick={() => closeModal()}>
                                <IconSprite id='edit_profile' />
                            </Link>
                            <div className="h-profile__info">
                                <div className="h-profile__name text_center font_w_500">{profile.firstName || ""} {profile.lastName || ""}</div>
                                <div className="h-profile__contact">
                                    <ul className="h-profile__contact-ul">
                                        <li className="h-profile__contact-li">
                                            <IconSprite id='writing' />
                                            {
                                                !!profile.email?.length ? <span>{profile.email}</span> : "Eлектронна пошта"
                                            }
                                        </li>
                                        <li className="h-profile__contact-li">
                                            <IconSprite id='phone' />
                                            <span>{profile.phone}</span>
                                        </li>
                                        <li className="h-profile__contact-li">
                                            <IconSprite id='date_profile' />
                                            {
                                                !!profile.data?.length ? <span>{profile.data}</span> : "Дата народження"
                                            }
                                        </li>
                                    </ul>
                                    <div className="h-profile__wr-bts">
                                        <Button label="Зворотній зв’язок" handleClick={() => handleOpenFaq()} />
                                    </div>
                                </div>
                            </div>
                            <div className="h-profile__bot">
                                <button className="h-profile__btn-exit" onClick={() => handleExit()}>
                                    <span>Вийти</span>
                                    <IconSprite id='exit_profile' />
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
        </div >
    )
}