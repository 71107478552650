import { FC } from "react";

import { IconSprite } from "../iconSprite";

export interface CardPtops {
    id: number,
    icon?: string,
    text: string,
    handleClose?: Function,
};

export const Card: FC<CardPtops> = ({
    id,
    icon = '',
    text = '',
    handleClose = () => { }
}) => {
    return (
        <div className="notitem">
            <button className="notitem__close" onClick={() => handleClose(id)}>
                <IconSprite id='icon_close_notif' />
            </button>
            <div className="notitem_cont">
                {
                    (icon.length > 0) && <IconSprite id={icon} />
                }
                <span>{text}</span>
            </div>
        </div>
    )
}