import { FC, useState } from 'react';

import { Button } from "../button";
import { FieldInput } from "../fieldInput";
import { FieldItextArea } from "../fieldItextArea";

import { useAppDispatch } from 'hooks/state';
import { sendContactForm } from '../../stores/controlers/faqs';

export interface ModalFaqProps { };

export const ModalFaq: FC<ModalFaqProps> = () => {
    const dispatch = useAppDispatch();
    const [load, setLoad] = useState<boolean>(false);
    const [data, setData] = useState<{ title: string, des: string }>({ title: "", des: "" });
    const [error, setError] = useState<{ title: string, des: string }>({ title: "", des: "" });
    const [errorServer, setErrorServer] = useState<boolean>(false);

    const onChange = (name: string, value: string) => {
        setData(prev => ({ ...prev, ...{ [name]: value } }))
    }

    const handleSend = () => {
        if (data.title.length < 2)
            setError((prev) => ({ ...prev, title: 'Заповніть поле' }));

        if (data.des.length < 5) {
            setError((prev) => ({ ...prev, des: 'Заповніть поле' }));
            return;
        }

        if (data.title.length < 2) {
            return;
        }

        sendContactForm({
            obj: { subject: data.title, text: data.des },
            setLoad,
            dispatch,
            setErrorServer,
        });
    }

    return (
        <div className="modal-content modal-content_478 g-modal-20">
            <div className='wr-modal-title'>
                <div className="modal__title text_center font_w_500"><span>Зворотній зв’язок</span></div>
            </div>
            <div className="form-faq">
                <div>
                    <FieldInput
                        placeholder='Тема'
                        errorMessage={error.title}
                        handleChange={(value: string) => onChange('title', value)}
                        value={data.title}
                    />
                </div>
                <div>
                    <FieldItextArea
                        placeholder='Введіть текст'
                        errorMessage={error.title}
                        handleChange={(value: string) => onChange('des', value)}
                        value={data.des}
                    />
                </div>
                {errorServer && (<p className="aform-p_error text_center">Не вдалося надіслати звернення.</p>)}
            </div>
            <div className="modal-wr-btn-one bot-modal-p">
                <Button label="Відправити" load={load} disabled={load || (data.title.length < 2) || (data.des.length < 2)} handleClick={() => handleSend()} />
            </div>
        </div>
    )
}