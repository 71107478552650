import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    addBooks,
    getAllBooks,
    getAllBooksFavorites,
    getAllLastBooks
} from "../controlers/books";

import { BookCard as BookCardinterf } from "./book";

export interface bookFile {
    duration: number | string,
    name: string,
    size: number | string,
}

export interface BookCard {
    id: number | string,
    author: string,
    description: string,
    duration: number | null,
    genre: string,
    isbn: string,
    nameBookFiles: bookFile[],
    path: string
    size: string,
    title: string,
    urlBookFiles: bookFile[],
    voice_acting: string,
    year: string,
    full_path_photo: string | null,
    isFavorite: boolean
}

export interface Pagiantion {
    current_page: number | string,
    last_page: number | string,
    per_page: number | string,
    from: number | string,
}

export interface BooksAll {
    bookCards: BookCard[] | [],
    bookLastActiveCards: BookCardinterf[] | []
    bookFavoriteCards: BookCard[] | [],
    pagination: Pagiantion | null,
    paginationFavorite: Pagiantion | null,
    paginationLastActive: Pagiantion | null,
    loadBtnActivatioBook: boolean,
    loadGetCardBooks: boolean,
    loadGetCardBooksFavorites: boolean,
    loadGetCardBooksLast: boolean,
}

const initialState: BooksAll = {
    bookCards: [],
    bookLastActiveCards: [],
    bookFavoriteCards: [],
    pagination: null,
    paginationFavorite: null,
    paginationLastActive: null,
    loadBtnActivatioBook: false,
    loadGetCardBooks: false,
    loadGetCardBooksFavorites: false,
    loadGetCardBooksLast: false,
}

export const slice = createSlice({
    name: "books",
    initialState,
    reducers: {
        setLoadCards(statusCode) {
            statusCode.loadGetCardBooks = true;
        },
        setFavoriteCardAll(state, action: PayloadAction<{ id: number | string, status: boolean }>) {
            let indexCard = state.bookCards.findIndex(el => (el.id === action.payload.id));
            state.bookCards[indexCard].isFavorite = action.payload.status;
        },
        setFavoriteCardLast(state, action: PayloadAction<{ id: number | string, status: boolean }>) {
            let indexCard = state.bookLastActiveCards.findIndex(el => (el.id === action.payload.id));
            state.bookLastActiveCards[indexCard].isFavorite = action.payload.status;
        },
        setLoadCardsFavorites(statusCode) {
            statusCode.loadGetCardBooksFavorites = true;
        },
        setLoadCardsLast(statusCode) {
            statusCode.loadGetCardBooksLast = true;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(addBooks.pending, (state, action) => {
                state.loadBtnActivatioBook = true;
            })
            .addCase(addBooks.fulfilled, (state, action: PayloadAction<{}>) => {
                state.loadBtnActivatioBook = false;
            })
            .addCase(getAllBooks.fulfilled, (state, action: PayloadAction<any>) => {
                state.bookCards = action.payload?.data?.books || [];
                state.pagination = action.payload?.data?.pagination || {};
                state.loadGetCardBooks = false;

                state.bookLastActiveCards = [];
                state.bookFavoriteCards = [];
            })
            .addCase(getAllBooksFavorites.fulfilled, (state, action: PayloadAction<any>) => {
                state.bookFavoriteCards = action.payload?.data?.books || [];
                state.paginationFavorite = action.payload?.data?.pagination || {};
                state.loadGetCardBooksFavorites = false;

                state.bookLastActiveCards = [];
                state.bookCards = [];
            })
            .addCase(getAllLastBooks.fulfilled, (state, action: PayloadAction<any>) => {
                state.bookLastActiveCards = action.payload?.data?.books || [];
                state.paginationLastActive = action.payload?.data?.pagination || {};
                state.loadGetCardBooksLast = false;

                state.bookFavoriteCards = [];
                state.bookCards = [];
            })
    }
});

export const {
    setLoadCards,
    setFavoriteCardAll,
    setFavoriteCardLast,
    setLoadCardsFavorites,
    setLoadCardsLast
} = slice.actions;

export default slice.reducer;