import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    getBook,
    favoriteSelectBook,
} from "../controlers/book";

export interface bookFile {
    duration: number | string,
    name: string,
    size: number | string,
}

interface lasAc {
    file_name: string
    stop_duration: number,
    total_duration: number,
    track_percentage: number,
    progress: number,
    time: string
}

export interface BookCard {
    id: number | string,
    author: string,
    description: string,
    duration: number | null,
    genre: string,
    isbn: string,
    nameBookFiles: bookFile[],
    path: string
    size: string,
    title: string,
    urlBookFiles: bookFile[],
    voice_acting: string,
    year: string,
    full_path_photo: string | null,
    isFavorite: boolean,
    lastActivity: lasAc
}

export interface BooksAll {
    bookCard: BookCard | null,
    loadGet: boolean,
}

const initialState: BooksAll = {
    bookCard: null,
    loadGet: false,
}

export const slice = createSlice({
    name: "book",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getBook.pending, (state, action) => {
                state.loadGet = true;
            })
            .addCase(getBook.fulfilled, (state, action: PayloadAction<any>) => {
                state.bookCard = action.payload.data;
                state.loadGet = false;
            })
            .addCase(favoriteSelectBook.fulfilled, (state, action: PayloadAction<any>) => {
                if (state.bookCard !== null)
                    state.bookCard.isFavorite = action.payload;
            })
    }
});

export const { } = slice.actions;

export default slice.reducer;