import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    getFilterGenres,
    getFilterAuthors,
    getFilterCollections
} from "../controlers/filters";

export interface BooksAll {
    genres: string[],
    author: string[],
    collection: { id: number, name: string }[],
}

const initialState: BooksAll = {
    genres: [],
    author: [],
    collection: []
}

export const slice = createSlice({
    name: "filters",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getFilterGenres.fulfilled, (state, action: PayloadAction<any>) => {
                let keys = Object.keys(action.payload);
                let arre: string[] = [];

                if (keys.length > 0)
                    arre = keys.map(item => action.payload[item]);

                state.genres = arre;
            })
            .addCase(getFilterAuthors.fulfilled, (state, action: PayloadAction<any>) => {
                let keys = Object.keys(action.payload);
                let arre: string[] = [];

                if (keys.length > 0)
                    arre = keys.map(item => action.payload[item]);

                state.author = arre;
            })
            .addCase(getFilterCollections.fulfilled, (state, action: PayloadAction<any>) => {
                state.collection = action.payload;
            })
    }
});

export const { } = slice.actions;

export default slice.reducer;