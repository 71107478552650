import { FC } from "react";

import { LayoutPlayer } from "./layoutPlayers";
import { Header } from "../components/header";


import { useAppSelector, useAppDispatch } from '../hooks/state';
import { setCloseModalAll } from "../stores/slices/theme";

export interface DefaultLayoutProps {
    isPlayer?: boolean,
    children?: JSX.Element
};

export const DefaultLayout: FC<DefaultLayoutProps> = ({
    children,
    isPlayer = false
}) => {
    const dispatch = useAppDispatch();
    // showMobMenu
    const { bgModal } = useAppSelector((state) => state.theme);

    // ${showMobMenu ? "no_scrol" : ""}
    return (
        <div className={`wrapper `}>
            <Header />
            <main className="main">
                {
                    !isPlayer && (children)
                }
                {
                    isPlayer && <LayoutPlayer>
                        {children}
                    </LayoutPlayer>
                }
            </main>
            {
                bgModal && <div className="modal-bg" onClick={() => dispatch(setCloseModalAll())}></div>
            }
        </div>
    )
}