import queryString from "query-string";

import { getCookeToken } from "../units/session";

export default class ApiClient {
    apiUrl: string;
    prefix: string;
    token: string | null;

    constructor({ apiUrl, prefix }: { apiUrl: string, prefix: string }) {
        if (!apiUrl?.length) throw new Error('[apiUrl] required');

        this.apiUrl = apiUrl;
        this.prefix = prefix;
        this.token = null;
    }

    async get({ url, params = {} }: { url: string, params?: Object }) {
        return this.methodRequest({
            url,
            params,
            method: "GET",
        });
    }

    async post({ url, payload = {}, type = "json" }: { url: string, payload: Object, type?: string }) {
        return this.methodRequest({
            url,
            method: "POST",
            body: payload,
            type
        });
    }

    async put({ url, payload = {} }: { url: string, payload: Object }) {
        return this.methodRequest({
            url,
            method: "PUT",
            body: payload
        });
    }

    async patch({ url, payload = {} }: { url: string, payload: Object }) {
        return this.methodRequest({
            url,
            method: "PATCH",
            body: payload,
        });
    }

    async delete({ url, payload = {} }: { url: string, payload: Object }) {
        return this.methodRequest({
            url,
            method: "DELETE",
            body: payload,
        });
    }
    setToken(token: string) {
        this.token = token;
    }
    getToken() {
        return getCookeToken();
    }

    async methodFetch({ url, options, attempts = 2 }: { url: string, options: object, attempts?: number }) {
        for (let i: number = 0; i < attempts; i++) {
            try {
                return await fetch(url, options);
            } catch (error) {
                let er = (error as Error);

                if (er && er.name === "TypeError" && er.message === "Network request failed") {
                    if (i >= attempts - 1) {
                        throw error;
                    } else {
                        await this.delay();
                    }
                } else {
                    throw error;
                }
            }
        }
    }

    async methodRequest(
        { url, method, params = {}, body, type = 'json' }:
            { url: string, method: string, params?: object, body?: object | undefined | any, type?: string | undefined }
    ) {
        const query = Object.keys(params).length ? `?${queryString.stringify(params, { arrayFormat: "comma" })}` : "";
        const requestUrl = `${this.apiUrl}/${this.prefix}/${url}${query}`;
        const headers = new Headers();
        const requestBody = (method === "GET") ? undefined : type === "formData" ? body : JSON.stringify({ ...body });

        headers.append("Accept", "application/json");

        if (type === "json") {
            headers.append("Content-Type", "application/json");
        }

        if (!this.token) {
            this.getToken();
        }

        if (this.token) {
            headers.append("Authorization", `Bearer ${this.token}`);
        }

        let FD = undefined;

        if (type === "formData") {
            FD = new FormData();

            console.log('Object.entries(body): ', Object.entries(body));

            for (const [name, value] of Object.entries(body)) {
                let val: any = value;
                FD.append(name, val);
            }
        }

        const options = {
            method,
            headers,
            redirect: "follow",
            withCredentials: true,
            crossDomain: false,
            body: (type === "formData") ? FD : requestBody,
        };

        try {
            const response = await this.methodFetch({ url: requestUrl, options });

            let json: {
                status?: string | number,
                data?: object | null,
                error?: object | string | boolean
            };

            try {
                json = await response?.json();
            } catch (error) {
                console.log("Wrong response error: ", error);

                const e = {
                    code: "INVALID_RESPONSE",
                    httpStatus: response?.status,
                };

                throw e;
            }

            if (json.status === 0) {
                throw json.error;
            }

            return json;
        } catch (error) {
            console.log("Server error: ", error);
        }
    }

    async delay() {
        return new Promise((res) => setTimeout(res, 15000));
    }
}