import { FC, MouseEventHandler } from "react";

export interface ButtonTextProps {
    label: string,
    handle: MouseEventHandler<HTMLButtonElement>
};

export const ButtonText: FC<ButtonTextProps> = ({
    label,
    handle
}) => {
    return (
        <button className="btn-text" type="button" onClick={handle}>{label}</button>
    )
}