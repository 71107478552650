import { FC } from "react";

const arr_prog_pc: number[] = [
    0,
    0.877,
    1.754,
    2.6310000000000002,
    3.508,
    4.385,
    5.2620000000000005,
    6.139,
    7.016,
    7.893,
    8.77,
    9.647,
    10.524000000000001,
    11.401,
    12.278,
    13.155,
    14.032,
    14.909,
    15.786,
    16.663,
    17.54,
    18.417,
    19.294,
    20.171,
    21.048000000000002,
    21.925,
    22.802,
    23.679,
    24.556,
    25.433,
    26.31,
    27.187,
    28.064,
    28.941,
    29.818,
    30.695,
    31.572,
    32.449,
    33.326,
    34.203,
    35.08,
    35.957,
    36.834,
    37.711,
    38.588,
    39.465,
    40.342,
    41.219,
    42.096000000000004,
    42.973,
    43.85,
    44.727,
    45.604,
    46.481,
    47.358,
    48.235,
    49.112,
    49.989,
    50.866,
    51.743,
    52.62,
    53.497,
    54.374,
    55.251,
    56.128,
    57.005,
    57.882,
    58.759,
    59.636,
    60.513,
    61.39,
    62.267,
    63.144,
    64.021,
    64.898,
    65.775,
    66.652,
    67.529,
    68.406,
    69.283,
    70.16,
    71.037,
    71.914,
    72.791,
    73.668,
    74.545,
    75.422,
    76.299,
    77.176,
    78.053,
    78.93,
    79.807,
    80.684,
    81.561,
    82.438,
    83.315,
    84.19200000000001,
    85.069,
    85.946,
    86.823,
    87.7,
    88.577,
    89.454,
    90.331,
    91.208,
    92.085,
    92.962,
    93.839,
    94.716,
    95.593,
    96.47,
    97.347,
    98.224,
    99.101,
    100,
];

const arr_prog_mob: number[] = [
    0,
    4.347,
    8.694,
    13.041,
    17.388,
    21.735,
    26.082,
    30.429,
    34.776,
    39.123,
    43.47,
    47.817,
    52.164,
    56.511,
    60.858,
    65.205,
    69.552,
    73.899,
    78.246,
    82.593,
    86.94,
    91.287,
    95.634,
    100
];

export interface PropgressProps {
    phone: boolean,
    interes: number,
};

export const Propgress: FC<PropgressProps> = ({
    phone,
    interes,
}) => {
    let intereInterest = interes * 100;

    return (
        phone ?
            <svg width="222" height="24" viewBox="0 0 222 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="6.89355" width="2" height="10.2128" rx="1" fill={intereInterest > arr_prog_mob[0] ? '#537486' : '#D0E0EA'} />
                <rect x="10" y="3.57471" width="2" height="16.8511" rx="1" fill={intereInterest > arr_prog_mob[1] ? '#537486' : '#D0E0EA'} />
                <rect x="20" y="3.57471" width="2" height="16.8511" rx="1" fill={intereInterest > arr_prog_mob[2] ? '#537486' : '#D0E0EA'} />
                <rect x="30" y="6.12744" width="2" height="11.7447" rx="1" fill={intereInterest > arr_prog_mob[3] ? '#537486' : '#D0E0EA'} />
                <rect x="40" y="6.89355" width="2" height="10.2128" rx="1" fill={intereInterest > arr_prog_mob[4] ? '#537486' : '#D0E0EA'} />
                <rect x="50" y="3.57471" width="2" height="16.8511" rx="1" fill={intereInterest > arr_prog_mob[5] ? '#537486' : '#D0E0EA'} />
                <rect x="60" y="3.57471" width="2" height="16.8511" rx="1" fill={intereInterest > arr_prog_mob[6] ? '#537486' : '#D0E0EA'} />
                <rect x="70" y="6.12744" width="2" height="11.7447" rx="1" fill={intereInterest > arr_prog_mob[7] ? '#537486' : '#D0E0EA'} />
                <rect x="80" y="8.68066" width="2" height="6.6383" rx="1" fill={intereInterest > arr_prog_mob[8] ? '#537486' : '#D0E0EA'} />
                <rect x="90" y="7.65967" width="2" height="8.68085" rx="1" fill={intereInterest > arr_prog_mob[9] ? '#537486' : '#D0E0EA'} />

                <rect x="100" y="3.57471" width="2" height="16.8511" rx="1" fill={intereInterest > arr_prog_mob[10] ? '#537486' : '#D0E0EA'} />
                <rect x="110" width="2" height="24" rx="1" fill={intereInterest > arr_prog_mob[11] ? '#537486' : '#D0E0EA'} />
                <rect x="120" y="2.55322" width="2" height="18.8936" rx="1" fill={intereInterest > arr_prog_mob[12] ? '#537486' : '#D0E0EA'} />
                <rect x="130" y="6.12744" width="2" height="11.7447" rx="1" fill={intereInterest > arr_prog_mob[13] ? '#537486' : '#D0E0EA'} />
                <rect x="140" y="8.68066" width="2" height="6.6383" rx="1" fill={intereInterest > arr_prog_mob[14] ? '#537486' : '#D0E0EA'} />
                <rect x="150" y="7.65967" width="2" height="8.68085" rx="1" fill={intereInterest > arr_prog_mob[15] ? '#537486' : '#D0E0EA'} />
                <rect x="160" y="2.55322" width="2" height="18.8936" rx="1" fill={intereInterest > arr_prog_mob[16] ? '#537486' : '#D0E0EA'} />
                <rect x="170" y="6.89355" width="2" height="10.2128" rx="1" fill={intereInterest > arr_prog_mob[17] ? '#537486' : '#D0E0EA'} />
                <rect x="180" y="3.57471" width="2" height="16.8511" rx="1" fill={intereInterest > arr_prog_mob[18] ? '#537486' : '#D0E0EA'} />
                <rect x="190" y="6.89355" width="2" height="10.2128" rx="1" fill={intereInterest > arr_prog_mob[19] ? '#537486' : '#D0E0EA'} />

                <rect x="200" y="6.89355" width="2" height="10.2128" rx="1" fill={intereInterest > arr_prog_mob[20] ? '#537486' : '#D0E0EA'} />
                <rect x="210" y="3.57471" width="2" height="16.8511" rx="1" fill={intereInterest > arr_prog_mob[21] ? '#537486' : '#D0E0EA'} />
                <rect x="220" y="6.12744" width="2" height="11.7447" rx="1" fill={intereInterest > arr_prog_mob[22] ? '#537486' : '#D0E0EA'} />
            </svg>
            :
            <svg width="1143" height="25" viewBox="0 0 1143 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect id="play_line_pc_0" x="0.103516" y="7.84668" width="2" height="10.2128" rx="1" fill={intereInterest > arr_prog_pc[0] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_1" x="10.1035" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[1] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_2" x="20.1035" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[2] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_3" x="30.1035" y="7.08057" width="2" height="11.7447" rx="1" fill={intereInterest >= arr_prog_pc[3] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_4" x="40.1035" y="7.84668" width="2" height="10.2128" rx="1" fill={intereInterest >= arr_prog_pc[4] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_5" x="50.1035" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[5] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_6" x="60.1035" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[6] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_7" x="70.1035" y="7.08057" width="2" height="11.7447" rx="1" fill={intereInterest >= arr_prog_pc[7] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_8" x="80.1035" y="9.63379" width="2" height="6.6383" rx="1" fill={intereInterest >= arr_prog_pc[8] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_9" x="90.1035" y="8.61279" width="2" height="8.68085" rx="1" fill={intereInterest >= arr_prog_pc[9] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_10" x="100.104" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[10] ? '#537486' : '#D0E0EA'} />

                <rect id="play_line_pc_11" x="110.104" y="7.08057" width="2" height="11.7447" rx="1" fill={intereInterest >= arr_prog_pc[11] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_12" x="120.104" y="9.63379" width="2" height="6.6383" rx="1" fill={intereInterest >= arr_prog_pc[12] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_13" x="130.104" y="7.84668" width="2" height="10.2128" rx="1" fill={intereInterest >= arr_prog_pc[13] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_14" x="140.104" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[14] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_15" x="150.104" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[15] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_16" x="160.104" y="7.08057" width="2" height="11.7447" rx="1" fill={intereInterest >= arr_prog_pc[16] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_17" x="170.104" y="7.84668" width="2" height="10.2128" rx="1" fill={intereInterest >= arr_prog_pc[17] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_18" x="180.104" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[18] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_19" x="190.104" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[19] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_20" x="200.104" y="7.08057" width="2" height="11.7447" rx="1" fill={intereInterest >= arr_prog_pc[20] ? '#537486' : '#D0E0EA'} />

                <rect id="play_line_pc_21" x="210.104" y="9.63379" width="2" height="6.6383" rx="1" fill={intereInterest >= arr_prog_pc[21] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_22" x="220.104" y="8.61279" width="2" height="8.68085" rx="1" fill={intereInterest >= arr_prog_pc[22] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_23" x="230.104" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[23] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_24" x="240.104" y="7.08057" width="2" height="11.7447" rx="1" fill={intereInterest >= arr_prog_pc[24] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_25" x="250.104" y="9.63379" width="2" height="6.6383" rx="1" fill={intereInterest >= arr_prog_pc[25] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_26" x="260.104" y="8.61279" width="2" height="8.68085" rx="1" fill={intereInterest >= arr_prog_pc[26] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_27" x="270.104" y="9.63379" width="2" height="6.6383" rx="1" fill={intereInterest >= arr_prog_pc[27] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_28" x="280.104" y="8.61279" width="2" height="8.68085" rx="1" fill={intereInterest >= arr_prog_pc[28] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_29" x="290.104" y="8.61279" width="2" height="8.68085" rx="1" fill={intereInterest >= arr_prog_pc[29] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_30" x="300.104" y="9.63379" width="2" height="6.6383" rx="1" fill={intereInterest >= arr_prog_pc[30] ? '#537486' : '#D0E0EA'} />

                <rect id="play_line_pc_31" x="310.104" y="8.61279" width="2" height="8.68085" rx="1" fill={intereInterest >= arr_prog_pc[31] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_32" x="320.104" y="9.63379" width="2" height="6.6383" rx="1" fill={intereInterest >= arr_prog_pc[32] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_33" x="330.104" y="8.61279" width="2" height="8.68085" rx="1" fill={intereInterest >= arr_prog_pc[33] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_34" x="340.104" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[34] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_35" x="350.104" y="7.08057" width="2" height="11.7447" rx="1" fill={intereInterest >= arr_prog_pc[35] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_36" x="360.104" y="9.63379" width="2" height="6.6383" rx="1" fill={intereInterest >= arr_prog_pc[36] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_37" x="370.104" y="8.61279" width="2" height="8.68085" rx="1" fill={intereInterest >= arr_prog_pc[37] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_38" x="380.104" y="9.63379" width="2" height="6.6383" rx="1" fill={intereInterest >= arr_prog_pc[38] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_39" x="390.104" y="8.61279" width="2" height="8.68085" rx="1" fill={intereInterest >= arr_prog_pc[39] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_40" x="400.104" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[40] ? '#537486' : '#D0E0EA'} />

                <rect id="play_line_pc_41" x="410.104" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[41] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_42" x="420.104" y="8.61279" width="2" height="8.68085" rx="1" fill={intereInterest >= arr_prog_pc[42] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_43" x="430.104" y="0.953125" width="2" height="24" rx="1" fill={intereInterest >= arr_prog_pc[43] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_44" x="440.104" y="3.50635" width="2" height="18.8936" rx="1" fill={intereInterest >= arr_prog_pc[44] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_45" x="450.104" y="3.50635" width="2" height="18.8936" rx="1" fill={intereInterest >= arr_prog_pc[45] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_46" x="460.104" y="3.50635" width="2" height="18.8936" rx="1" fill={intereInterest >= arr_prog_pc[46] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_47" x="470.104" y="7.84668" width="2" height="10.2128" rx="1" fill={intereInterest >= arr_prog_pc[47] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_48" x="480.104" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[48] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_49" x="490.104" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[49] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_50" x="500.104" y="7.08057" width="2" height="11.7447" rx="1" fill={intereInterest >= arr_prog_pc[50] ? '#537486' : '#D0E0EA'} />

                <rect id="play_line_pc_51" x="510.104" y="9.63379" width="2" height="6.6383" rx="1" fill={intereInterest >= arr_prog_pc[51] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_52" x="520.104" y="8.61279" width="2" height="8.68085" rx="1" fill={intereInterest >= arr_prog_pc[52] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_53" x="530.104" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[53] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_54" x="540.104" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[54] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_55" x="550.104" y="8.61279" width="2" height="8.68085" rx="1" fill={intereInterest >= arr_prog_pc[55] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_56" x="560.104" y="0.953125" width="2" height="24" rx="1" fill={intereInterest >= arr_prog_pc[56] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_57" x="570.104" y="3.50635" width="2" height="18.8936" rx="1" fill={intereInterest >= arr_prog_pc[57] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_58" x="580.104" y="3.50635" width="2" height="18.8936" rx="1" fill={intereInterest >= arr_prog_pc[58] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_59" x="590.104" y="3.50635" width="2" height="18.8936" rx="1" fill={intereInterest >= arr_prog_pc[59] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_60" x="600.104" y="7.84668" width="2" height="10.2128" rx="1" fill={intereInterest >= arr_prog_pc[60] ? '#537486' : '#D0E0EA'} />

                <rect id="play_line_pc_61" x="610.104" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[61] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_62" x="620.104" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[62] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_63" x="630.104" y="7.08057" width="2" height="11.7447" rx="1" fill={intereInterest >= arr_prog_pc[63] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_64" x="640.104" y="9.63379" width="2" height="6.6383" rx="1" fill={intereInterest >= arr_prog_pc[64] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_65" x="650.104" y="8.61279" width="2" height="8.68085" rx="1" fill={intereInterest >= arr_prog_pc[65] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_66" x="660.104" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[66] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_67" x="670.104" y="7.08057" width="2" height="11.7447" rx="1" fill={intereInterest >= arr_prog_pc[67] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_68" x="680.104" y="9.63379" width="2" height="6.6383" rx="1" fill={intereInterest >= arr_prog_pc[68] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_69" x="690.104" y="8.61279" width="2" height="8.68085" rx="1" fill={intereInterest >= arr_prog_pc[69] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_70" x="700.104" y="9.63379" width="2" height="6.6383" rx="1" fill={intereInterest >= arr_prog_pc[70] ? '#537486' : '#D0E0EA'} />

                <rect id="play_line_pc_71" x="710.104" y="8.61279" width="2" height="8.68085" rx="1" fill={intereInterest >= arr_prog_pc[71] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_72" x="720.104" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[72] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_73" x="730.104" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[73] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_74" x="740.104" y="8.61279" width="2" height="8.68085" rx="1" fill={intereInterest >= arr_prog_pc[74] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_75" x="750.104" y="0.953125" width="2" height="24" rx="1" fill={intereInterest >= arr_prog_pc[75] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_76" x="760.104" y="3.50635" width="2" height="18.8936" rx="1" fill={intereInterest >= arr_prog_pc[76] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_77" x="770.104" y="3.50635" width="2" height="18.8936" rx="1" fill={intereInterest >= arr_prog_pc[77] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_78" x="780.104" y="3.50635" width="2" height="18.8936" rx="1" fill={intereInterest >= arr_prog_pc[78] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_79" x="790.104" y="7.08057" width="2" height="11.7447" rx="1" fill={intereInterest >= arr_prog_pc[79] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_80" x="800.104" y="9.63379" width="2" height="6.6383" rx="1" fill={intereInterest >= arr_prog_pc[80] ? '#537486' : '#D0E0EA'} />

                <rect id="play_line_pc_81" x="810.104" y="8.61279" width="2" height="8.68085" rx="1" fill={intereInterest >= arr_prog_pc[81] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_82" x="820.104" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[82] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_83" x="830.104" y="7.08057" width="2" height="11.7447" rx="1" fill={intereInterest >= arr_prog_pc[83] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_84" x="840.104" y="9.63379" width="2" height="6.6383" rx="1" fill={intereInterest >= arr_prog_pc[84] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_85" x="850.104" y="8.61279" width="2" height="8.68085" rx="1" fill={intereInterest >= arr_prog_pc[85] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_86" x="860.104" y="9.63379" width="2" height="6.6383" rx="1" fill={intereInterest >= arr_prog_pc[86] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_87" x="870.104" y="8.61279" width="2" height="8.68085" rx="1" fill={intereInterest >= arr_prog_pc[87] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_88" x="880.104" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[88] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_89" x="890.104" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[89] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_90" x="900.104" y="8.61279" width="2" height="8.68085" rx="1" fill={intereInterest >= arr_prog_pc[90] ? '#537486' : '#D0E0EA'} />

                <rect id="play_line_pc_91" x="910.104" y="0.953125" width="2" height="24" rx="1" fill={intereInterest >= arr_prog_pc[91] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_92" x="920.104" y="3.50635" width="2" height="18.8936" rx="1" fill={intereInterest >= arr_prog_pc[92] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_93" x="930.104" y="3.50635" width="2" height="18.8936" rx="1" fill={intereInterest >= arr_prog_pc[93] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_94" x="940.104" y="8.61279" width="2" height="8.68085" rx="1" fill={intereInterest >= arr_prog_pc[94] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_95" x="950.104" y="0.953125" width="2" height="24" rx="1" fill={intereInterest >= arr_prog_pc[95] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_96" x="960.104" y="3.50635" width="2" height="18.8936" rx="1" fill={intereInterest >= arr_prog_pc[96] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_97" x="970.104" y="3.50635" width="2" height="18.8936" rx="1" fill={intereInterest >= arr_prog_pc[97] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_98" x="980.104" y="3.50635" width="2" height="18.8936" rx="1" fill={intereInterest >= arr_prog_pc[98] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_99" x="990.104" y="3.50635" width="2" height="18.8936" rx="1" fill={intereInterest >= arr_prog_pc[99] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_100" x="1000.1" y="3.50635" width="2" height="18.8936" rx="1" fill={intereInterest >= arr_prog_pc[100] ? '#537486' : '#D0E0EA'} />

                <rect id="play_line_pc_101" x="1010.1" y="3.50635" width="2" height="18.8936" rx="1" fill={intereInterest >= arr_prog_pc[101] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_102" x="1020.1" y="7.84668" width="2" height="10.2128" rx="1" fill={intereInterest >= arr_prog_pc[102] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_103" x="1030.1" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[103] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_104" x="1040.1" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[104] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_105" x="1050.1" y="7.08057" width="2" height="11.7447" rx="1" fill={intereInterest >= arr_prog_pc[105] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_106" x="1060.1" y="3.50635" width="2" height="18.8936" rx="1" fill={intereInterest >= arr_prog_pc[106] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_107" x="1070.1" y="7.84668" width="2" height="10.2128" rx="1" fill={intereInterest >= arr_prog_pc[107] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_108" x="1080.1" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[108] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_109" x="1090.1" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[109] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_110" x="1100.1" y="7.84668" width="2" height="10.2128" rx="1" fill={intereInterest >= arr_prog_pc[110] ? '#537486' : '#D0E0EA'} />

                <rect id="play_line_pc_111" x="1110.1" y="7.84668" width="2" height="10.2128" rx="1" fill={intereInterest >= arr_prog_pc[111] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_112" x="1120.1" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[112] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_113" x="1130.1" y="4.52783" width="2" height="16.8511" rx="1" fill={intereInterest >= arr_prog_pc[113] ? '#537486' : '#D0E0EA'} />
                <rect id="play_line_pc_114" x="1140.1" y="7.08057" width="2" height="11.7447" rx="1" fill={intereInterest >= arr_prog_pc[114] ? '#537486' : '#D0E0EA'} />
            </svg>
    )
}