import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    getFaqs
} from "../controlers/faqs";

interface dataItemFaq {
    id: number,
    question: string,
    answer: string
}

interface dataFaqs {
    home: dataItemFaq[],
    recent: dataItemFaq[],
    favorite: dataItemFaq[],
    book: dataItemFaq[],
}

const initialState: dataFaqs = {
    home: [],
    recent: [],
    favorite: [],
    book: []
}

export const slice = createSlice({
    name: "faqs",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFaqs.fulfilled, (state, action: PayloadAction<any>) => {
                return action.payload;
            })
    }
});

export default slice.reducer;