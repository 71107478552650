import Base from "./base";

export default class AuthApi extends Base {
    addBook(data: object) {
        return this.api.post({ url: `user-active-archive`, payload: data });
    }
    getAllActiveBooks(filter: object) {
        return this.api.get({ url: "filter-books", params: filter });
    }
    getAllFavoriteBooks(filter: object) {
        return this.api.get({ url: "filter-favorite-books", params: filter });
    }
    getAllLastBooks(filter: object) {
        return this.api.get({ url: "filter-last-active-books", params: filter });
    }
    // are not used
    getArchives() {
        return this.api.get({ url: "user-archives" });
    }
    // are not used
    getLifetime() {
        return this.api.get({ url: "user-lifetime-passed-books" });
    }
}