import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppDispatch, RootState } from "../index";

import api from "../../api";

export const getAppTerm = createAsyncThunk<
    {},
    undefined,
    { dispatch: AppDispatch, state: RootState }
>('terms/getPageInfotTerm',
    async (_, { dispatch, getState }) => {
        const res = await api.dataPagesApi.getAppTerm();
        return res?.data;
    }
)

export const getAppInfo = createAsyncThunk<
    {},
    undefined,
    { dispatch: AppDispatch, state: RootState }
>('terms/getAppInfo',
    async (_, { dispatch, getState }) => {
        const res = await api.dataPagesApi.getAppInfo();
        return res?.data;
    }
)

export const getPolicy = createAsyncThunk<
    {},
    undefined,
    { dispatch: AppDispatch, state: RootState }
>('terms/getPolicy',
    async (_, { dispatch, getState }) => {
        const res = await api.dataPagesApi.getPolicy();
        return res?.data;
    }
)
