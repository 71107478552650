import { FC, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { useLocation, Link } from 'react-router-dom';

import { IconSprite } from "../iconSprite";
import { Button } from "../button";

import { useAppDispatch } from "../../hooks/state";
import { setModalOpen } from "../../stores/slices/modals";

import routers from "../../constants/routers.json";

interface MobMenuProps {
    show: boolean,
    closeMenu: Function,
    handleOpenFaq: Function
};
interface LinkProps {
    to: string,
    name: string,
    iconNormal: string,
    clickFun?: Function,
};

const LinkActive: FC<LinkProps> = ({
    to,
    name,
    iconNormal,
    clickFun = () => { }
}) => {
    let location = useLocation();

    return (
        <Link
            to={to}
            onClick={() => clickFun()} className={`mmenu__a ${(location.pathname === to) ? "active" : " "}`}
        >
            <i>
                <IconSprite id={iconNormal} />
            </i>
            <span>{name}</span>
        </Link>
    )
}

export const MobMenu: FC<MobMenuProps> = ({
    show,
    closeMenu,
    handleOpenFaq
}) => {
    const dispatch = useAppDispatch();

    const nodeRef = useRef<HTMLDivElement>(null);

    const clickFun = () => closeMenu();

    return (
        <CSSTransition nodeRef={nodeRef} in={show} timeout={100} unmountOnExit={true}>
            {
                (state) => {
                    return (
                        <div className={`mob-menu mob-menu_${state}`} ref={nodeRef}>
                            <div className="mob-menu_m">
                                <button className="mob-menu__btn-close" onClick={() => closeMenu()}>
                                    <IconSprite id="icon_mob_menu_close" />
                                </button>
                                <div className="mob-menu__content">
                                    <nav className="mmenu">
                                        <ul className="mmenu__list">
                                            <li className="mmenu__li">
                                                <LinkActive
                                                    to={routers.home.link}
                                                    name={routers.home.name}
                                                    clickFun={clickFun}
                                                    iconNormal="icon_mob_menu_pla"
                                                />
                                            </li>
                                            <li className="mmenu__li">
                                                <LinkActive
                                                    to={routers.recentActivity.link}
                                                    name={routers.recentActivity.name}
                                                    clickFun={clickFun}
                                                    iconNormal="icon_mob_menu_time"
                                                />
                                            </li>
                                            <li className="mmenu__li">
                                                <LinkActive
                                                    to={routers.favorites.link}
                                                    name={routers.favorites.name}
                                                    clickFun={clickFun}
                                                    iconNormal="icon_mob_menu_five"
                                                />
                                            </li>
                                            <li className="mmenu__li">
                                                <LinkActive
                                                    to={routers.editProfile.link}
                                                    name={routers.editProfile.name}
                                                    clickFun={clickFun}
                                                    iconNormal="icon_mob_menu_profile"
                                                />
                                            </li>
                                            <li className="mmenu__li">
                                                <button className="mmenu__a no_stro" onClick={() => { clickFun(); dispatch(setModalOpen('code')); }}>
                                                    <i>
                                                        <IconSprite id="icon_mob_menu_plus" />
                                                    </i>
                                                    <span>Додати книгу</span>
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div className="bot-mob-menu">
                                        <Button label="Зворотній зв’язок" handleClick={() => handleOpenFaq()} />
                                        <Link to={routers.termsAndConditions.link} className="mob-menu__bot_link">Правила та умови </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            }
        </CSSTransition>
    )
}
