import { FC, useEffect, useState } from "react";

import { Banner } from "../components/banner";
import { Filter } from "../components/filter";
import { NoList } from "../components/noList";
import { Button } from "../components/button";
import { CardAudio } from "../components/cardAudio";
import { Pagination } from "../components/pagination";
import { Faqs } from "../components/faq";

import { useAppDispatch, useAppSelector } from "../hooks/state";
import { setModalOpen } from "../stores/slices/modals";
import { getAllBooks } from "../stores/controlers/books";
import { addFavorite, detachFavorite } from "../stores/controlers/book";
import { addBooks } from "../stores/controlers/books";
import { useFilter } from "../hooks/filter";

export interface HomePageProps { };

export const HomePage: FC<HomePageProps> = () => {
    const [firts, setFirst] = useState<boolean>(false);
    const [firtsBook, setFirstBook] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { theme, books: { bookCards, loadGetCardBooks, pagination }, faqs } = useAppSelector((state:any) => state);
    const { handleFilter, getFilter, searchParams, deleteParams } = useFilter();

    const handlePage = (v: number) => {
        handleFilter({ key: 'page', name: `${v}` });
    }

    const handleFavorite = (id: number, status: boolean | number) => {
        if (!status)
            dispatch(addFavorite({ id, filter: getFilter() }));

        if (status)
            dispatch(detachFavorite({ id, filter: getFilter() }));
    }

    const deleteP = () => {
        deleteParams();
    }

    useEffect(() => {
        if (!firts)
            setFirst(true);

        if (firts) dispatch(getAllBooks({
            filter: getFilter()
        }));
    }, [firts, searchParams, searchParams]);

    useEffect(() => {
        if (!firtsBook)
            setFirstBook(true);

        if (firtsBook) {
            let kesBook = searchParams.get('key');

            if (!!kesBook) {
                dispatch(addBooks({ key: kesBook, calback: deleteP }));
            }
        }
    }, [firtsBook]);

    return (
        <div className="global-page-ptop page-p-b">
            <Banner />
            <Filter
                handleFilter={handleFilter}
                activeAuthor={searchParams.get('author') || ''}
                activeTime={searchParams.get('size') || ''}
                activeGenres={searchParams.get('genres') || ''}
                activeСollection={searchParams.get('archive_id') || ''}
                lenBook={1}
            />
            <div className="wr-grid-audio">
                <div className="container wr-grid-audio_cont">
                    {
                        ((bookCards.length === 0) && !loadGetCardBooks) && (
                            <NoList
                                title="Немає  доданих аудіокниг..."
                                text="Додайте аудіокнигу та насолоджуйтесь прослуховуванням!"
                            />
                        )
                    }
                    <div className="grid-cards-aydio">
                        {
                            loadGetCardBooks && [...new Array(3)].map((_, index) => (
                                <div className="card_load" key={index}></div>
                            ))
                        }
                        {
                            !loadGetCardBooks && bookCards.map((itemCard: any, index: number) => (
                                <CardAudio
                                    key={index}
                                    id={itemCard.id}
                                    img={itemCard.full_path_photo || ''}
                                    file={itemCard.urlBookFiles?.[0]?.name || ''}
                                    title={itemCard.title}
                                    des={itemCard.author}
                                    performer={itemCard.voice_acting}
                                    year={itemCard.year}
                                    fileType={itemCard.isbn}
                                    fileSize={itemCard.size}
                                    time={itemCard.duration}
                                    fileList={itemCard.urlBookFiles}
                                    favorite={itemCard.isFavorite}
                                    handleFavorite={() => handleFavorite(Number(itemCard.id), itemCard.isFavorite)}
                                    nameBookFiles={itemCard.nameBookFiles}
                                />
                            ))
                        }
                    </div>
                </div>
                {
                    !loadGetCardBooks && (pagination?.last_page !== undefined) && (Number(pagination.last_page) > 1) && (
                        <div className="container">
                            <Pagination
                                handlePage={handlePage}
                                activePage={Number(pagination.current_page)}
                                pages={Number(pagination.last_page)}
                            />
                        </div>
                    )
                }
            </div>
            <Faqs
                title="Питання що часто задаються"
                subTitle=""
                data={faqs.home}
            />
            {
                !theme.phone && (
                    <div className="bot-wr-add">
                        <Button label="Додати книгу" handleClick={() => dispatch(setModalOpen('code'))} />
                    </div>
                )
            }
        </div>
    )
}