import { useSearchParams } from "react-router-dom";

interface OutUseFilter {
    handleFilter: Function,
    getFilter: Function,
    searchParams: any,
    deleteParams: Function
}

export const useFilter = (): OutUseFilter => {
    const [searchParams, setSearchParams] = useSearchParams();

    const handleFilter = ({ key, name }: { key: string, name: string }) => {
        let iskey = false;

        if (searchParams.get(key) === name) {
            searchParams.delete(key);
            iskey = true;
        }

        let fAuthor = searchParams.get('author');
        let fGenres = searchParams.get('genres');
        let fTime = searchParams.get('size');
        let fpage = searchParams.get('page');
        let archive_id = searchParams.get('archive_id');

        if (key !== 'page') {
            fpage = '1';
        }

        let obj: {
            author?: string,
            genres?: string,
            size?: string,
            page?: string,
            archive_id?: string
        } = {};

        if (fAuthor) obj.author = fAuthor;
        if (fGenres) obj.genres = fGenres;
        if (fTime) obj.size = fTime;
        if (fpage) obj.page = fpage;
        if (archive_id) obj.archive_id = archive_id;

        if (iskey)
            setSearchParams({ ...obj });

        if (!iskey)
            setSearchParams({ ...obj, ...{ [key]: name } });
    }

    const getFilter = () => {
        let fAuthor = searchParams.get('author');
        let fGenres = searchParams.get('genres');
        let fTime = searchParams.get('size');
        let fpage = searchParams.get('page') || '1';
        let archive_id = searchParams.get('archive_id');

        let obj: {
            author?: string,
            genre?: string,
            size?: string,
            page?: string,
            archive_id?: string
        } = {};

        if (fAuthor) obj.author = fAuthor;
        if (fGenres) obj.genre = fGenres;
        if (fTime) obj.size = fTime;
        if (fpage) obj.page = fpage;
        if (archive_id) obj.archive_id = archive_id;

        return obj;
    }

    const deleteParams = () => {
        setSearchParams({});
    }

    return {
        handleFilter,
        getFilter,
        searchParams,
        deleteParams
    }
}