export const formatTime = (seconds: number, back: boolean = false, interes: number = 0) => {
    let millis = seconds;

    if (back) {
        millis -= ((seconds * (interes * 100)) / 100);
    }

    millis *= 1000;

    const date = new Date(millis);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = padString(date.getUTCSeconds());
    if (hh) {
        return `${hh}:${padString(mm)}:${ss}`;
    }
    return `${mm}:${ss}`;
};

const padString = (string: number) => {
    return ("0" + string).slice(-2);
};