import { FC } from "react";
import Slider from '@mui/material/Slider';

import { IconSprite } from "../iconSprite";

export interface VolumeProps {
    value: number,
    handleChange: any
};

export const Volume: FC<VolumeProps> = ({
    value,
    handleChange = () => { }
}) => {
    return (
        <div className="slider-volume">
            <button className="slider-volume__btn" onClick={() => (value === 0) ? handleChange("val", 50) : handleChange('non', 0)}>
                <IconSprite id={(value === 0) ? "icon_player_volume_none" : "icon_player_volume_min"} />
            </button>
            <Slider
                aria-label="Default"
                valueLabelDisplay="auto"
                value={value}
                onChange={handleChange}
            />
            <button className="slider-volume__btn" onClick={() => handleChange(null, 100)}>
                <IconSprite id={"icon_player_volume_max"} />
            </button>
        </div>
    )
}