import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ModalType = 'code' | 'format' | 'time' | 'speed' | 'colection' | 'faq';

export interface CounterState {
    show: boolean,
    modal: ModalType,
    idCard: number,
}

const initialState: CounterState = {
    show: false,
    modal: 'code',
    idCard: 0
};

export const slice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        setModalOpen(state, action: PayloadAction<ModalType>) {
            state.show = true;
            state.modal = action.payload;
        },
        setCloseModal(state) {
            state.show = false;
            state.modal = 'code';
        },
        setModalIdCardstate(state, action: PayloadAction<number>) {
            state.idCard = action.payload;
        }
    }
});

export const {
    setModalOpen,
    setCloseModal,
    setModalIdCardstate
} = slice.actions;

export default slice.reducer;
