import { FC } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { DefaultLayout } from "./layouts/default";
import { Autorization } from "./components/autorization";

import { useAppSelector } from "./hooks/state";

import dataRouters from "./constants/routers.json";

export interface AppRouterProps {
    isHome?: boolean,
    isPlayer?: boolean,
    isPublick?: boolean,
    component: React.ElementType<any>
};

export const AppRouter: FC<AppRouterProps> = ({
    isHome = false,
    isPlayer = false,
    isPublick = false,
    component: Component,
}) => {
    let location = useLocation();
    const { auth, dataProfile, isAuthLoad } = useAppSelector((state) => state.session);

    if (isPublick) {
        return (
            <DefaultLayout isPlayer={isPlayer}>
                <Component />
            </DefaultLayout>
        )
    }

    if (((!auth || !dataProfile) && !isHome) && !isAuthLoad) {
        return <Navigate to={dataRouters.home.link} state={{ from: location }} replace />;
    }

    return (
        <>
            {
                ((!auth && dataProfile) || (auth && !dataProfile) || (!auth && !dataProfile)) && (
                    <Autorization />
                )
            }
            {
                auth && dataProfile && (
                    <DefaultLayout isPlayer={isPlayer}>
                        <Component />
                    </DefaultLayout>
                )
            }
        </>
    );
}
