import Base from "./base";

export default class AuthApi extends Base {
    getBook(id: (string | number)) {
        return this.api.get({ url: `show-user-active-book/${id}` });
    }
    addFavorite(obj: object) {
        return this.api.post({ url: `attach-favorite-books`, payload: obj });
    }
    detachFavorite(obj: object) {
        return this.api.post({ url: `detach-favorite-books`, payload: obj });
    }
    updateLacActivity(obj: object) {
        return this.api.post({ url: `create-or-update-last-activity`, payload: obj });
    }
    downloadBook(obj: object) {
        return this.api.post({ url: ``, payload: obj });
    }
    downloadBookMp3(obj: object) {
        return this.api.post({ url: `download-mp3-file`, payload: obj });
    }
    downloadBookJob(obj: object) {
        return this.api.post({ url: `download-book-job`, payload: obj });
    }
    downloadBookJobStatus(id: number, file_name: string) {
        return this.api.get({ url: `queue-status-download-book`, params: { job: id, file_name } });
    }
}