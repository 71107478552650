import { FC } from "react";

import { useAppSelector } from "hooks/state";

import imgBanner from "../assets/img/img_banner.png";

interface BannerProps { };

export const Banner: FC<BannerProps> = () => {
    const { theme: { phone }, dataPages: { apInfo } } = useAppSelector(state => state);

    return (
        !phone ? (
            <div className="banner">
                <div className="container">
                    <div className="banner__row">
                        <div className="banner__img">
                            <img src={imgBanner} alt="" />
                        </div>
                        <div className="banner__cont">
                            <h1 className="banner__title" dangerouslySetInnerHTML={{ __html: apInfo.title }}></h1>
                            <p className="banner__des" dangerouslySetInnerHTML={{ __html: apInfo.description }}></p>
                        </div>
                    </div>
                </div>
            </div>
        ) : <></>
    )
}