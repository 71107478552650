import { FC, useState, useRef, useEffect, MouseEventHandler } from "react";

import { SlideRange } from "./slideReangeEdit";
import { Button } from "./button";

import { pScale, pPosition } from "../units/editAvat";
import { useDispatch } from "react-redux";

export interface EdipPhotoAvatarProps {
    fileImg: string | undefined,
    handleCloseEdit: Function,
    handleGenerat: Function
};

export const EdipPhotoAvatar: FC<EdipPhotoAvatarProps> = ({
    handleCloseEdit,
    handleGenerat,
    fileImg
}) => {
    const [scale, setScale] = useState<number>(50);
    const [position, setPosition] = useState<number>(50);
    const [widthPos, setWidthPos] = useState<number>(1);
    const [posInParent, setPosInParent] = useState<number>(10);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const refAvaCan = useRef<HTMLDivElement>(null);
    const devCirc = useRef<HTMLDivElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const refImgFull = useRef<HTMLImageElement>(null);
    const [imgWH, setImgWH] = useState<{ w: number, h: number }>({ w: 0, h: 0 });

    const handleChangeScale = (value: number) => setScale(value);
    const handleChangePosition = (value: number) => setPosition(value);

    const handleAppy = () => {
        new Promise(resolve => {
            const canvas = canvasRef.current;
            if ((canvas === null) || (fileImg === undefined) || (devCirc.current === null) || (imgRef === null)) return;
            const context: CanvasRenderingContext2D | null = canvas.getContext('2d');
            if (context === null) return;

            const image = new Image();
            image.src = fileImg;

            context.clearRect(0, 0, canvas.width, canvas.height);

            image.onload = function () {
                canvas.width = !!devCirc.current?.offsetWidth ? devCirc.current?.offsetWidth : 0;
                canvas.height = !!devCirc.current?.offsetHeight ? devCirc.current?.offsetHeight : 0;

                const posImg: any = imgRef.current?.getBoundingClientRect();
                const posCir: any = devCirc.current?.getBoundingClientRect();

                const posImtT = posImg.top - posCir.top;
                const posImtL = posImg.left - posCir.left;

                const cWidth = context.canvas.width;
                const inputWidth = imgWH.w * pScale(scale);
                // const inputHeight = !!refAvaCan.current?.offsetHeight ? refAvaCan.current?.offsetHeight : 0 * pScale(scale);

                // const inputHeight = !!refImgFull.current?.offsetWidth ? refImgFull.current?.offsetWidth : 0 * pScale(scale);
                // const inputWidth = image.naturalWidth * pScale(scale);
                const inputHeight = imgWH.h * pScale(scale);

                context.arc(cWidth / 2, cWidth / 2, cWidth / 2, 0, Math.PI * 2, true);
                context.clip();

                context.drawImage(image, posImtL, posImtT, inputWidth, inputHeight);

                resolve(canvas.toDataURL());
            }
        }).then(res => {
            handleGenerat(res);
            handleCloseEdit();
        })
    }

    useEffect(() => {
        if (!devCirc.current) return;
        setWidthPos(devCirc.current.offsetWidth);
        setPosInParent(devCirc.current.offsetLeft);

        setTimeout(() => {
            setImgWH({
                w: !!refImgFull.current?.offsetWidth ? refImgFull.current?.offsetWidth : 0,
                h: !!refImgFull.current?.offsetWidth ? refImgFull.current?.offsetHeight : 0
            });
        }, 50);
    }, [fileImg]);

    return (
        <>
            <div className="avatar-canvas" ref={refAvaCan}>
                <canvas ref={canvasRef} />
                <img
                    ref={refImgFull}
                    src={fileImg}
                    className="avatar-canvas__img"
                    style={{
                        transform: `translateX(${pPosition(position, widthPos)}px) translateY(-${imgWH.h / 2}px) scale(${pScale(scale)})`,
                        width: `${!!refAvaCan.current?.offsetWidth ? refAvaCan.current?.offsetWidth : 0}px`,
                    }}
                />
                <div className="avatar-canvas_bg"></div>
                <div className="avatar-canvas_pos" ref={devCirc}>
                    <div className="avatar-canvas_pos_wr-img" >
                        <img
                            src={fileImg}
                            ref={imgRef}
                            className="avatar-canvas_pos_img"
                            style={{
                                transform: `translateX(${pPosition(position, widthPos) - posInParent}px) translateY(-${imgWH.h / 2}px) scale(${pScale(scale)}`,
                                width: `${!!refAvaCan.current?.offsetWidth ? refAvaCan.current?.offsetWidth : 0}px`,
                            }}
                        />
                    </div>
                    <div className="avatar-canvas_pos_kv avatar-canvas_pos_kv_go"></div>
                    <div className="avatar-canvas_pos_kv avatar-canvas_pos_kv_ve"></div>
                </div>
            </div>
            <div className="avatar-cont mod-ava__pad">
                <div>
                    <div className="l-rang-t">Збільшити</div>
                    <SlideRange
                        value={scale}
                        handleChange={handleChangeScale}
                    />
                </div>
                <div className="mt-r-e">
                    <div className="l-rang-t">Вирівняти</div>
                    <SlideRange
                        value={position}
                        handleChange={handleChangePosition}
                    />
                </div>
                <div className="mt-wr-btn-e">
                    <Button label="Відмінити" type="b" handleClick={() => handleCloseEdit()} />
                    <Button label="Зберегти" handleClick={handleAppy} />
                </div>
            </div>
        </>
    )
}