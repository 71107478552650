import { FC, useEffect, useRef, useState } from "react";
import { Transition } from "react-transition-group";

import { IconSprite } from "./iconSprite";

import { activeItem } from "../units/selects";

interface ItemList {
    id: string | number,
    name: string,
    value: string
};

export interface SelectListProps {
    list: ItemList[],
    activeKey?: string,
    handle?: Function
};

export const SelectList: FC<SelectListProps> = ({
    list,
    activeKey = '',
    handle = () => { }
}) => {
    const [show, setShow] = useState<boolean>(false);
    const [heightOpen, setHeightOpen] = useState<number>(0);
    const nodeRef = useRef<HTMLUListElement>(null);

    const open = () => setShow(true);
    const close = () => setShow(false);

    const handleSelect = (value: string) => {
        handle(value);
        close();
    }

    useEffect(() => {
        if (show)
            setHeightOpen(nodeRef.current?.offsetHeight ? nodeRef.current?.offsetHeight : 0);

        if (!show)
            setHeightOpen(0);
    }, [show]);

    return (
        <div className={`select ${show ? "open" : ""}`}>
            <div className="select__top" onClick={show ? close : open}>
                <span>{activeItem(list, activeKey)}</span>
                <i>
                    <IconSprite id='icon_arrow_bot_select' />
                </i>
            </div>
            <div className="select__wr-list" style={{ height: `${heightOpen}px` }}>
                <Transition nodeRef={nodeRef} in={show} timeout={300} unmountOnExit={true}>
                    {(state) => (
                        <ul className={`select__list select__list_${state}`} ref={nodeRef}>
                            {
                                list.map(item => {
                                    if (item.value === activeKey)
                                        return;

                                    return (
                                        <li className="select__list-item" key={item.id}>
                                            <button className={`${item.value === activeKey ? "active" : ""}`} onClick={() => handleSelect(item.value)}>{item.name}</button>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    )}
                </Transition>
            </div>
        </div>
    )
}