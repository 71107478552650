import { FC, useState } from 'react';

import { Button } from "../button";
import { SelectList } from '../selectList';

import dataSelect from "../../constants/dataSelects.json";
import { useAppDispatch, useAppSelector } from 'hooks/state';
import { downloadBook } from "../../stores/controlers/book";

export interface ModalDownloadProps { };

export const ModalDownload: FC<ModalDownloadProps> = () => {
    const dispatch = useAppDispatch();
    const [select, setSelect] = useState<string>('mp3');
    const [loadDow, setLoadDow] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const { modals: { idCard } } = useAppSelector(state => state);

    const handleSelec = (value: string) => setSelect(value);

    const handleSend = () => {
        if (progress == 0) {
            dispatch(downloadBook({ id: idCard, setLoadDow, setProgress, typeBook: select }));
        }
    }

    return (
        <div className="modal-content modal-content_386 g-modal-20">
            <div className="modal__title modal__title_icon text_center font_w_500"><span>Виберіть формат для завантаження</span></div>
            <SelectList
                list={dataSelect.downloadForma}
                activeKey={select}
                handle={handleSelec}
            />
            {
                loadDow && (<div className='inf-down'>Підготовка файлів для завантаження, може зайняти до 2хв.</div>)
            }
            <div className="modal-wr-btn-one">
                <Button label={(progress > 0) ? `${progress} %` : "Завантажити"} load={loadDow} disabled={loadDow} handleClick={() => handleSend()} />
            </div>
        </div>
    )
}