import { FC } from "react";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import routers from "../../constants/routers.json";

interface MenuHeadProps {
    clickFun?: Function
};
interface LinkProps {
    to: string,
    name: string,
    clickFun?: Function
};

const LinkActive: FC<LinkProps> = ({
    to,
    name,
    clickFun = () => { }
}) => {
    let location = useLocation();

    return (
        <Link to={to} onClick={() => clickFun()} className={`${(location.pathname === to) ? "active" : " "}`}>{name}</Link>
    )
}

export const MenuHead: FC<MenuHeadProps> = ({
    clickFun
}) => {
    return (
        <nav className="menu">
            <ul className="menu_ul">
                <li className="menu_li">
                    <LinkActive to={routers.home.link} name={routers.home.name} clickFun={clickFun} />
                </li>
                <li className="menu_li">
                    <LinkActive to={routers.recentActivity.link} name={routers.recentActivity.name} clickFun={clickFun} />
                </li>
                <li className="menu_li">
                    <LinkActive to={routers.favorites.link} name={routers.favorites.name} clickFun={clickFun} />
                </li>
            </ul>
        </nav>
    )
}