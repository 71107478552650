import apiClient from "./apiClient";

import AuthApi from "./auth";
import ProfileApi from "./profile";
import booksApi from "./books";
import bookApi from "./book";
import filterApi from "./filters";
import dataPages from "./dataPages";
import faqsApi from "./faqs";

export default function apiConstructor({ apiUrl, prefix }: { apiUrl: string, prefix: string, }) {
    const api: apiClient = new apiClient({ apiUrl, prefix });

    return {
        api,
        authApi: new AuthApi({ api }),
        ProfileApi: new ProfileApi({ api }),
        booksApi: new booksApi({ api }),
        bookApi: new bookApi({ api }),
        filterApi: new filterApi({ api }),
        dataPagesApi: new dataPages({ api }),
        faqsApi: new faqsApi({ api }),
    }
}