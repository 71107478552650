import { FC, MouseEventHandler } from "react";

import { HeadForm } from "./headForm";
import { FieldInputPhone } from "../fieldInputPhone";
import { Button } from "../button";
import { ButtonText } from "../buttonText";

import { validPhone } from "../../units/valids";

import { FormloginInterface } from "./forms";

export interface LoginProps {
    handleWindow: Function,
    send: MouseEventHandler<HTMLButtonElement>,
    setFormLogin: Function,
    formLogin: FormloginInterface,
    loadBtn: boolean,
};

export const Login: FC<LoginProps> = ({
    handleWindow,
    send,
    formLogin,
    setFormLogin,
    loadBtn = false
}) => {
    const updateSet = ({ name, value }: { name: string, value: string | boolean }) => {
        setFormLogin((prev: object) => ({ ...prev, ...{ [name]: value } }));
    }

    return (
        <div className="aform__registr ">
            <HeadForm desc="Увійдіть та користуйтеся платформою зручно!" />
            <div className="aform__m-360 aform_gap aform_gap_18 aform_login">
                <FieldInputPhone name='phone' placeholder='Ваш номер телефону' valueIn={formLogin.phone} handleChange={(value: string) => updateSet({ name: 'phone', value })} />
                <div className="aform__wr_btn aform_login_wr_btn">
                    <Button label="Увійти" load={loadBtn} disabled={!validPhone(formLogin.phone)} handleClick={send} />
                </div>
                <div className="aform__text_b font_w_300">Немає акаунту? <ButtonText label="Зареєструватися" handle={() => handleWindow('up')} /></div>
            </div>
        </div>
    )
}