export const seconByInteres = (duration: number): number => {
    return ((10 * 100) / duration) / 100;
}

export const nextAudio = ({ list, current }: { list: any[], current: string }): string => {
    if (list.length === 0)
        return 'end';

    let nextTrack = list.findIndex(item => item.name === current);
    nextTrack += 1;

    let track = list?.[nextTrack]?.name;

    if (!track?.length)
        return 'end';

    return track;
}

export const prevAudio = ({ list, current }: { list: any[], current: string }): string => {
    if (list.length === 0)
        return 'end';

    let prevTrack = list.findIndex(item => item.name === current);
    prevTrack -= 1;

    let track = list?.[prevTrack]?.name;

    if (!track?.length)
        return 'end';

    return track;
}

export const searchLastFile = (file: string, arFiles: { name: string }[]): string => {
    let ret = arFiles.find(item => item.name.includes(file))?.name;
    if (!!ret) return ret;
    return ''
}