import { FC, useEffect } from "react";
import { useIMask } from 'react-imask';

import { FieldInput } from "./fieldInput";

export interface FieldInputPhoneProps {
    placeholder?: string,
    name?: string,
    errorMessage?: string,
    valueIn?: string,
    handleChange?: Function,
    disabled?: boolean,
    onEnter?: Function
};

export const FieldInputPhone: FC<FieldInputPhoneProps> = ({
    valueIn,
    disabled = false,
    ...props
}) => {
    const {
        ref,
        value,
        setValue,
    } = useIMask({ mask: '+380 00 000 00 00' }, /* { onAccept, onComplete } */); 

    const handleFocus = () => {
        if (value.length === 0)
            setValue('+380');
    }

    useEffect(() => {
        if (typeof valueIn == 'string')
            setValue(valueIn);
    }, [valueIn]);

    return (
        <FieldInput
            refe={ref}
            value={value}
            handleFocus={handleFocus}
            disabled={disabled}
            {...props}
        />
    )
}