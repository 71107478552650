import { FC } from "react";

import { IconSprite } from "../components/iconSprite";

import { useAppSelector } from "hooks/state";

export interface TermsAndConditionsProps { };

export const TermsAndConditionsPage: FC<TermsAndConditionsProps> = () => {
    const { dataPages: { terms } } = useAppSelector(state => state);

    return (
        <div className="container">
            <div className="tcontent">
                <div className="tcontent__head">
                    <button className="tcontent__btn_back" onClick={() => window.history.back()}>
                        <IconSprite id='icon_arrow_back' />
                    </button>
                    <h1 className="tcontent__title" dangerouslySetInnerHTML={{ __html: terms.title }}></h1>
                </div>
                <div className="tcontent-text" dangerouslySetInnerHTML={{ __html: terms.description }}></div>
            </div>
        </div>
    )
}