import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { contSessAuthCode, sessionAuth } from "../controlers/sessions";

export interface CounterState {
    auth: boolean,
    dataProfile: boolean,
    loadingPhone: 'idle' | 'pending' | 'succeeded' | 'failed',
    isAuthLoad: boolean
}

const initialState: CounterState = {
    auth: false,
    dataProfile: true,
    loadingPhone: 'idle',
    isAuthLoad: false,
};

export const slice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setAuth(state, action: PayloadAction<boolean>) {
            state.auth = action.payload;
        },
        setDataProfile(state, action: PayloadAction<boolean>) {
            state.dataProfile = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(contSessAuthCode.pending, (state, action) => {
                //    console.log(load);
            })
            .addCase(contSessAuthCode.fulfilled, (state, action: PayloadAction<{ dataProfile: boolean, auth: boolean }>) => {
                state.auth = action.payload.auth;
                state.dataProfile = action.payload.dataProfile;
            })
            .addCase(sessionAuth.pending, (state, action) => {
                state.isAuthLoad = true;
            })
            .addCase(sessionAuth.fulfilled, (state, action: PayloadAction<{ dataProfile: boolean, auth: boolean }>) => {
                state.auth = action.payload.auth;
                state.dataProfile = action.payload.dataProfile;
                state.isAuthLoad = false;
            });
    },
});

export const { setAuth, setDataProfile } = slice.actions;

export default slice.reducer;
