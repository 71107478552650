import { FC } from "react";
import Slider from '@mui/material/Slider';

import { IconSprite } from "./iconSprite";

export interface SlideRangeProps {
    value: number,
    handleChange: any
};

export const SlideRange: FC<SlideRangeProps> = ({
    value,
    handleChange = () => { }
}) => {
    return (
        <div className="slider-volume slider_range">
            <button className="slider-volume__btn" onClick={() => handleChange((value >= 5) ? (value - 5) : 0)}>
                <IconSprite id='icon_range_minus' />
            </button>
            <Slider
                aria-label="Default"
                valueLabelDisplay="auto"
                value={value}
                onChange={(e: any) => handleChange(e.target.value)}
            />
            <button className="slider-volume__btn" onClick={() => handleChange((value < 100) ? (value + 5) : 100)}>
                <IconSprite id='icon_range_plus' />
            </button>
        </div>
    )
}