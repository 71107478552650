import { FormProfileInterface } from "../components/autorization/forms";

import { ProfileInt } from "../stores/slices/profile";

export const validPhone = (val: string) => {
    return val.trim().length === 17;
}

export const validFormUp = ({ phone, terms }: { phone: string, terms: boolean }) => {
    return validPhone(phone) && terms;
}

export const validFormCode = ({ code, thereTime }: { code: string, thereTime: boolean }) => {
    return (code.trim().length === 11) && thereTime;
}

export const validName = (val: string): boolean => {
    return val.length > 1;
}

export const validData = (val: string): boolean => {
    if (val.length === 0)
        return true;

    return val.length === 10;
}

export const validEmail = (val: string): boolean => {
    if (val.length === 0)
        return true;

    return /^[^ ]+@[^ ]+\.[a-z]{2,20}$/.test(val);
}

export const validEmailRequired = (val: string): boolean => {
    return /^[^ ]+@[^ ]+\.[a-z]{2,20}$/.test(val);
}

export const validFormProfile = (obj: FormProfileInterface): boolean => {
    return validName(obj.firstName) && validName(obj.lastName) && validEmailRequired(obj.email);
}

export const validSettingsProfile = (obj: ProfileInt): boolean => {
    if ((obj.firstName === null) || (obj.lastName === null))
        return false;

    return validName(obj.firstName) && validName(obj.lastName);
}

export const validNameRegular = (val: string): boolean => {
    return /^([[a-zA-Zа-яА-ЯёЁІі]{1}[a-zA-Zа-яА-ЯёЁІі0-9_]{1,50})$/gm.test(val);
}

export const validFormCodeBook = (val: string) => {
    return val.trim().length >= 5;
}

export const dopAllValidEmail = ({ value, error, set }: { value: string, error: boolean, set: Function }): void => {
    if (error) {
        set("Будь ласка, введіть коректну електронну адресу!")
    }

    if (!value.includes('@')) {
        set('Пошта має містити символ @');
    }

    if (value.includes('@')) {
        if (!(/^[^ ]+\.[a-z]{2, 20}$/.test(value))) {
            set('Не вірний домен пошти. Наприклад, має бути .ua, .com та інші');
        }
    }

    if (value.includes('@')) {
        if ((/^[^ ]+\.[a-z]{2, 20}$/.test(value))) {
            if (!value?.includes('.')) {
                set('Пропустили символ "."');
            }
        }
    }
}