import { FC } from "react";

import { PlayerBook } from "../components/playerBook";

import { useAppSelector, useAppDispatch } from "hooks/state";
import { setModalOpen } from "../stores/slices/modals";
import {
    currentPlayerStartOrPause,
    nextTrackAction,
    updateStatusPlayer,
    cleanPlayer
} from "../stores/slices/player";
import { updateLacActivity } from "../stores/controlers/book";

import { nextAudio, prevAudio } from "../units/players";

export interface LayoutPlayerProps {
    children?: JSX.Element
};

export const LayoutPlayer: FC<LayoutPlayerProps> = ({
    children
}) => {
    const dispatch = useAppDispatch();
    const { player: { playCard, automaticShutdown, speed, startProgress }, theme } = useAppSelector(state => state);
    const { id, listFile, statusPlayer, nameBookFiles } = playCard;

    const openModalSpeed = () => dispatch(setModalOpen('speed'));
    const openModalTime = () => dispatch(setModalOpen('time'));
    const handlePlay = () => dispatch(currentPlayerStartOrPause(true));
    const handlePause = () => dispatch(currentPlayerStartOrPause(false));
    const prevTrack = () => {
        let fileUrl = prevAudio({ list: listFile || [], current: playCard.audioUrl });

        if (fileUrl !== 'end') {
            dispatch(updateStatusPlayer(false));
            setTimeout(() => {
                dispatch(nextTrackAction(fileUrl));
                setTimeout(() => dispatch(updateStatusPlayer(true)), 50);
            }, 50);
        }

        // if the end of the tunes
        if (fileUrl === 'end')
            handlePause();
    };
    const nextTrack = () => handlePauseP();
    const handlePauseP = () => {
        let fileUrl = nextAudio({ list: listFile || [], current: playCard.audioUrl });

        if (fileUrl !== 'end') {
            dispatch(updateStatusPlayer(false));
            setTimeout(() => {
                dispatch(nextTrackAction(fileUrl));
                setTimeout(() => dispatch(updateStatusPlayer(true)), 50);
            }, 50);
        }

        // if the end of the tunes
        if (fileUrl === 'end')
            handlePause();
    }

    const handleUpdateLastActiveTrack = (duration: number, percentage: number) => {
        if (id === null) return;
        let nameFile = nameBookFiles?.find(item => playCard.audioUrl.includes(item.name))?.name;
        if (!nameFile) return;

        dispatch(updateLacActivity({
            id: id,
            fileName: nameFile,
            stopDuration: duration,
            trackPercentage: Math.floor(percentage * 100)
        }));
    }

    const handleCleanPlayer = () => {
        dispatch(cleanPlayer());
    }

    return (
        <div className={`layout-player-book ${(playCard.audioUrl.length > 0) ? "show_player" : ""}`}>
            <div className="layout-player-book__wrapper">{children}</div>
            <PlayerBook
                track={playCard.audioUrl}
                playing={playCard.status}
                startProgres={startProgress}
                statusPlayer={statusPlayer}
                openModalSpeed={openModalSpeed}
                openModalTime={openModalTime}
                prevTrack={prevTrack}
                nextTrack={nextTrack}
                handlePlay={handlePlay}
                handlePause={handlePause}
                isPhone={theme.phone}
                automaticShutdown={automaticShutdown}
                speed={speed}
                handlePauseP={handlePauseP}
                handleUpdateLastActiveTrack={handleUpdateLastActiveTrack}
                cleanPlayer={handleCleanPlayer}
            />
        </div>
    )
}