import { createAsyncThunk } from '@reduxjs/toolkit';

import api from "../../api";

import { AppDispatch, RootState } from "../index";
import { ProfileInt } from "../../stores/slices/profile";
import { setDataProfile } from "../../stores/slices/session";
import { aboutConver } from "../../units/converDataProfile";
import { converDataProfile } from "../../units/converDataProfile";
import { addNotification } from "../slices/notifications";

import { FormProfileInterface } from "../../components/autorization/forms";

export const profileEdit = createAsyncThunk<
    ProfileInt,
    { dataForm?: FormProfileInterface, page?: boolean, setErrorEmail: Function },
    { dispatch: AppDispatch, state: RootState }
>('profile/profileEdit',
    async ({ dataForm, page = false, setErrorEmail }, { dispatch, getState }) => {
        const { profile } = getState();

        const res = await api.ProfileApi.updateDataProfile(profile.data.id, aboutConver(page ? profile.data : dataForm));

        if (res?.success && res?.data?.id) {
            dispatch(setDataProfile(true));
            dispatch(addNotification({ message: "Оновлено" }));
        }

        if (!res?.success) {
            if (res.message === 'The email has already been taken.') {
                setErrorEmail('Електронна пошта вже зайнята.');
                dispatch(addNotification({ message: "Електронна пошта вже зайнята." }));
            }
        }

        return converDataProfile(res?.data);
    }
)

export const profileEditAvatarBase = createAsyncThunk<
    ProfileInt,
    { avatarCode: string },
    { dispatch: AppDispatch, state: RootState }
>('profile/profileEditAvatarBase4',
    async ({ avatarCode }, { dispatch, getState }) => {
        const { profile } = getState();

        const res = await api.ProfileApi.updateDataProfile(profile.data.id, { avatar_base64: avatarCode });

        return converDataProfile(res?.data);
    }
)

export const profileEditAvatar = createAsyncThunk<
    ProfileInt,
    { avatarCode: File },
    { dispatch: AppDispatch, state: RootState }
>('profile/profileEditAvatar',
    async ({ avatarCode }, { dispatch, getState }) => {
        const { profile } = getState();
        const res = await api.ProfileApi.updateDataProfileAvatar(profile.data.id, { photo: avatarCode });

        return converDataProfile(res?.data);
    }
)