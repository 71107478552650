import { FC, useRef, useState } from "react";

import { IconSprite } from "../iconSprite";

interface ItemFaqProps {
    title: string,
    des: string
};

export const ItemFaq: FC<ItemFaqProps> = ({
    title,
    des
}) => {
    const [show, setShow] = useState<boolean>(false);
    let ref = useRef<HTMLDivElement>(null);

    return (
        <div className={`item-fag ${show ? "open" : ""}`}>
            <div className="item-fag__top" onClick={() => setShow(prev => !prev)}>
                <div dangerouslySetInnerHTML={{ __html: des }}></div>
                <IconSprite id="icon_arrow_bot_accord" />
            </div>
            <div className="item-fag__show" style={{ height: show ? `${ref.current?.offsetHeight}px` : 0 }}>
                <div className="item-fag__des" ref={ref} dangerouslySetInnerHTML={{ __html: title }}></div>
            </div>
        </div>
    )
}