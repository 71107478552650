import { ProfileInt } from "../stores/slices/profile";

export const converDataProfile = (data: any): ProfileInt => {
    return {
        id: data?.id ? data.id : null,
        firstName: !!data?.first_name ? data.first_name : '',
        lastName: !!data?.last_name ? data.last_name : '',
        phone: !!data?.phone ? data.phone : '',
        data: !!data?.date_birth ? data.date_birth : '',
        email: !!data?.email ? data.email : '',
        urlAvatar: !!data?.url_avatar ? data.url_avatar : '',
        avatarBase64: !!data?.avatar_base64 ? data.avatar_base64 : '',
    }
}

export const aboutConver = (data: any): any => {
    let obj: any = {
        first_name: !!data?.firstName ? data.firstName : '',
        last_name: !!data?.lastName ? data.lastName : '',
        date_birth: !!data?.data ? data.data : '',
    };

    if (!!data?.email) obj.email = data.email;

    return obj;
}
