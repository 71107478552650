export const donwFile = (data: any, type: string = 'zip') => {

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `download.${type}`);
    link.setAttribute('type', `application/${type}`);
    link.setAttribute('Content-Desposition', `attachment; filename=your_filename.${type}`);
    document.body.appendChild(link);
    link.click();
}