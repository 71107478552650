import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { AppDispatch, RootState } from "../index";
import { getAllBooks, getAllBooksFavorites, getAllLastBooks } from "./books";
import { setFavoriteCardAll, setFavoriteCardLast } from "../slices/books";
import { donwFile } from "../../helpers/downloadFile";

import { API_URL, API_PREFIX } from "../../config";

import api from "../../api";

export const getBook = createAsyncThunk<
    {},
    { id: (string | number) },
    { dispatch: AppDispatch, state: RootState }
>('book/getBook',
    async ({ id }, { dispatch, getState }) => {
        const res = await api.bookApi.getBook(id);

        return {
            data: res.data || []
        };
    }
)

export const favoriteSelectBook = createAsyncThunk<
    {},
    undefined,
    { dispatch: AppDispatch, state: RootState }
>('book/addFavorite',
    async (_, { dispatch, getState }) => {
        const { book: { bookCard } } = getState();

        if (!bookCard) return false;

        if (!bookCard.isFavorite) {
            const res = await api.bookApi.addFavorite({ book_id: bookCard.id });
            if (!!res?.success)
                return true;
        }

        if (bookCard.isFavorite) {
            const res = await api.bookApi.detachFavorite({ book_id: bookCard.id });
            if (!!res?.success)
                return false;
        }

        return false;
    }
)

export const addFavorite = createAsyncThunk<
    {},
    { id: (string | number), allPage?: string, filter?: Object },
    { dispatch: AppDispatch, state: RootState }
>('book/addFavorite',
    async ({ id, allPage = 'all', filter = {} }, { dispatch, getState }) => {
        const res = await api.bookApi.addFavorite({ book_id: id });

        if (!!res?.success) {
            if (allPage === 'all')
                dispatch(setFavoriteCardAll({ id, status: true }));

            if (allPage === 'fav')
                dispatch(getAllBooksFavorites({ filter, loade: false }));

            if (allPage === 'active')
                dispatch(setFavoriteCardLast({ id, status: true }));
        }
    }
)

export const detachFavorite = createAsyncThunk<
    {},
    { id: (string | number), allPage?: string, filter?: Object },
    { dispatch: AppDispatch, state: RootState }
>('book/detachFavorite',
    async ({ id, allPage = 'all', filter = {} }, { dispatch, getState }) => {
        const res = await api.bookApi.detachFavorite({ book_id: id });

        if (!!res?.success) {
            if (!!res?.success) {
                if (allPage === 'all')
                    dispatch(setFavoriteCardAll({ id, status: false }));

                if (allPage === 'fav')
                    dispatch(getAllBooksFavorites({ filter, loade: false }));

                if (allPage === 'active')
                    dispatch(setFavoriteCardLast({ id, status: false }));
            }
        }
    }
)

export const updateLacActivity = createAsyncThunk<
    {},
    { id: (string | number), fileName: string, stopDuration: number, trackPercentage: number },
    { dispatch: AppDispatch, state: RootState }
>('book/updateLacActivity',
    async ({ id, fileName, stopDuration, trackPercentage }, { dispatch, getState }) => {
        const res = await api.bookApi.updateLacActivity({ book_id: id, file_name: fileName, stop_duration: stopDuration, track_percentage: trackPercentage });
    }
);

// type "zip" / "mp3"
export const downloadBook = createAsyncThunk<
    {},
    { id: number, type?: string, typeBook?: string, setLoadDow?: Function, setProgress?: Function },
    { dispatch: AppDispatch, state: RootState }
>('book/downloadBook',
    async ({ id, type = 'mp3', typeBook = "book", setLoadDow = () => { }, setProgress = () => { } }, { dispatch, getState }) => {
        let idTime: any = null;
        let col = 0;
        setLoadDow(true);

        const res = await api.bookApi.downloadBookJob({ book_id: id, type: typeBook });

        if (res?.data?.jobId) {
            idTime = setInterval(async () => {
                if (col === 150) {
                    setLoadDow(false);
                    clearInterval(idTime);
                }

                col += 1;

                const resStatus = await api.bookApi.downloadBookJobStatus(res.data.jobId, res?.data.file_name);

                if (!!resStatus?.data?.status) {
                    clearInterval(idTime);

                    // data :  {status: true, file_size: 4096}
                    await axios.get(`${API_URL}/${API_PREFIX}/download-book/${res.data.file_name}`, {
                        responseType: 'blob',
                        headers: {
                            'Authorization': `Bearer ${api.api.getToken()}`
                        },
                        onDownloadProgress: progressEvent => {
                            const percentage = Math.round(
                                (progressEvent.loaded * 100) / (resStatus.data.file_size || 1)
                            );

                            setProgress(percentage);

                            if (percentage === 1) {
                                setTimeout(() => {
                                    setLoadDow(false);
                                }, 200);
                            }

                            if (percentage === 100) {
                                setTimeout(() => {
                                    setProgress(0);
                                }, 400);
                            }
                        }
                    })
                        .then(response => {
                            donwFile(response.data);
                            setLoadDow(false);
                            setProgress(0);
                        })
                }

                if (resStatus?.statusCode !== 200) {
                    clearInterval(idTime);
                    setLoadDow(false);
                }
            }, 5e3);
        }
    }
)

export const downloadFile = createAsyncThunk<
    {},
    { id: number, fileName: string, setLoadDownItem: Function },
    { dispatch: AppDispatch, state: RootState }
>('book/downloadFile',
    async ({ id, fileName, setLoadDownItem }, { dispatch, getState }) => {
        setLoadDownItem((prev: string[]) => [...prev, fileName]);

        await axios.post(`${API_URL}/${API_PREFIX}/download-mp3-file`,
            {
                book_id: id,
                file_name: fileName,
                type: 'mp3'
            },
            {
                headers: {
                    'Authorization': `Bearer ${api.api.getToken()}`,
                    "Content-Type": "application/json"
                },
                responseType: 'blob',
            })
            .then(response => {
                donwFile(response.data, "mp3");

                setLoadDownItem((prev: string[]) => prev.filter(item => item !== fileName));
            })
    }
)




