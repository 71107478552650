import { FC, MouseEventHandler, useEffect, useState } from "react";

import { HeadForm } from "./headForm";
import { FieldInputCode } from "../fieldInputCode";
import { Button } from "../button";
import { ButtonText } from "../buttonText";
import { ReadOut } from "./readOut";

import { validFormCode } from "../../units/valids";

import { FormCodeInterface } from "./forms";

export interface CodeProps {
    handleRepeatCode: Function,
    send: MouseEventHandler<HTMLButtonElement>,
    setFormDode: Function,
    formCode: FormCodeInterface,
    repeatCode: boolean,
    loadBtn: boolean,
    errorCode: boolean,
    isOneRepeat: boolean,
    setRepeatCode: Function
};

export const Code: FC<CodeProps> = ({
    handleRepeatCode,
    send,
    formCode,
    setFormDode,
    repeatCode,
    setRepeatCode,
    loadBtn,
    errorCode,
}) => {
    const [thereTime, setThereTime] = useState<boolean>(true);

    const updateSet = ({ name, value }: { name: string, value: string | boolean }) => {
        setFormDode((prev: object) => ({ ...prev, ...{ [name]: value } }));
    }

    const handleThereTime = () => {
        setThereTime(false);
        setRepeatCode(true);
    }

    useEffect(() => {
        if (!repeatCode) setThereTime(true);
    }, [repeatCode])

    return (
        <div className="aform__registr">
            <HeadForm errorCode={errorCode} desc="На Ваш телефон відправлено код для перевірки актуальності данних" />
            <div className="aform__m-360 aform_gap aform_gap_18 aform_code">
                <FieldInputCode name="code" placeholder="Ввести код" valueIn={formCode.code} handleChange={(value: string) => updateSet({ name: 'code', value })} />
                <div className="aform_gap aform_gap_12">
                    <div className="aform__wr_btn aform_code_wr_btn">
                        <Button label="Відправити" load={loadBtn} disabled={!validFormCode({ code: formCode.code, thereTime })} handleClick={send} />
                    </div>
                    <div className={`aform__text_b font_w_300 ${repeatCode ? '' : 'non'}`}>Не отримали код? <ButtonText label="Відправити повторно" handle={() => handleRepeatCode()} /></div>
                    <ReadOut
                        setThereTime={handleThereTime}
                        isTime={repeatCode}
                    />
                </div>
            </div>
        </div>
    )
}