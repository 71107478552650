import { FC } from "react";

import { Item } from "./accordion/itemAudio";

import { timeCard } from "../units/timeOut";

import { PlayCard } from "../stores/slices/player";

export interface BookSectionProps {
    idBook: number | string,
    nameBookFiles: any[],
    urlBookFiles: any[],
    isPhone: boolean,
    lastActive: {
        file_name: string,
        track_percentage: number,
    } | undefined,
    player: PlayCard,
    handlePlayItem: Function,
    downFile: Function,
    loadDownItem: string[]
};

export const BookSection: FC<BookSectionProps> = ({
    idBook,
    nameBookFiles,
    urlBookFiles,
    isPhone,
    lastActive,
    player,
    handlePlayItem,
    downFile,
    loadDownItem
}) => {
    return (
        <div className="b-sec">
            <div className="books_container_content">
                <h3 className="b-sec__title"><span>Аудіо доріжок</span> {urlBookFiles.length}</h3>
                <div className="b-sec__accard">
                    {
                        urlBookFiles.map((item, index) => {
                            let lasActive: boolean = (player.id === idBook) ?
                                (!!player?.audioUrl?.length ? !!(player?.audioUrl === item.name) : !!(lastActive?.file_name !== undefined) && item.name.includes(lastActive?.file_name))
                                : !!(lastActive?.file_name !== undefined) && item.name.includes(lastActive?.file_name);

                            return (
                                <Item
                                    key={index}
                                    id={index}
                                    index={index}
                                    isBord={true}
                                    active={lasActive}
                                    time={timeCard(item.duration, false)}
                                    isPlayer={player.status && (item.name === player.audioUrl)}
                                    fileUrl={item.name}
                                    isPhone={isPhone}
                                    nameFile={nameBookFiles[index].name || ""}
                                    handlePlayItem={handlePlayItem}
                                    downFile={downFile}
                                    isLoadDown={!!loadDownItem.find(el => el === nameBookFiles[index].name)}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}