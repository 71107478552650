import { FC } from "react";

import { IconSprite } from "../iconSprite";

export interface ItemProps {
    id?: number,
    open?: boolean,
    main?: boolean,
    handleOpen?: Function,
    handleClose?: Function,
    isPhone?: boolean,
    isBord?: boolean,
    index: number,
    time: string,
    active: boolean,
    isPlayer: boolean,
    handlePlayItem: Function,
    fileUrl: string,
    downFile: Function,
    nameFile: string,
    isLoadDown: boolean
};

export const Item: FC<ItemProps> = ({
    id,
    open = false,
    main = false,
    isPhone = false,
    isBord = false,
    index,
    time,
    active,
    isPlayer,
    handlePlayItem,
    fileUrl,
    downFile,
    nameFile,
    isLoadDown
}) => {
    return (
        <div className={`accordion-item ${active ? "active" : ""} ${main ? "accordion-item_main" : ""} ${isBord ? "borde" : ""}`}>
            <div className="accordion-item__left">
                <div className="accordion-item__wr-btn">
                    <button className={`accordion-item__btn-play}`} onClick={() => handlePlayItem({ player: !isPlayer, fileUrl })}>
                        <IconSprite id={isPlayer ? "icon_accord_pause" : "icon_accord_play"} />
                    </button>
                </div>
                {
                    isPhone && (
                        <button className={`accordion-item__btn-down ${isLoadDown ? "loade" : ""}`} onClick={() => downFile(nameFile)}>
                            <IconSprite id='icon_arrow_down' />
                        </button>
                    )
                }
            </div>
            <div className="accordion-item__box">
                <div className="accordion-item__centr cen-accor" onClick={() => handlePlayItem({ player: !isPlayer, fileUrl })}>
                    <div className="cen-accor__h"><i>Аудіо файл {index+1}</i></div>
                    <div className="cen-accor__time">{time}</div>
                </div>
                <div className="accordion-item__right">
                    {
                        !isPhone && (
                            <button className={`accordion-item__btn-down ${isLoadDown ? "loade" : ""}`} onClick={() => downFile(nameFile)}>
                                <IconSprite id='icon_arrow_down' />
                            </button>
                        )
                    }
                </div>
            </div>
        </div>
    )
}