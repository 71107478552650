import { FC } from "react";
import { Link } from "react-router-dom";

import { CardPanel } from "./cardPanel";
import { IconSprite } from "./iconSprite";

import { useAppSelector, useAppDispatch } from "../hooks/state";
import { setPlayerCard, currentPlayerStartOrPause } from "../stores/slices/player";
import { bookFile } from "../stores/slices/books";
import { setModalOpen, setModalIdCardstate } from "../stores/slices/modals";
import { timeCard } from "../units/timeOut";

import routers from "../constants/routers.json";

export interface CardAudioProps {
    id: number | string | null,
    img: string,
    file: any,
    title?: string,
    des?: string,
    performer?: string,
    year?: string,
    fileType?: string,
    fileSize?: string,
    time?: null | number,
    favorite?: boolean,
    fileList?: bookFile[],
    handleFavorite?: Function,
    nameBookFiles: bookFile[],
};

export const CardAudio: FC<CardAudioProps> = ({
    id = null,
    img,
    file,
    title = '',
    des = '',
    performer = '',
    year = '',
    fileType = '',
    fileSize = '',
    time = '',
    favorite = false,
    fileList = [],
    handleFavorite = () => { },
    nameBookFiles
}) => {
    const dispatch = useAppDispatch();
    const { playCard } = useAppSelector(state => state.player);

    const handlePlay = () => {
        dispatch(currentPlayerStartOrPause(false));
        setTimeout(() => {
            dispatch(setPlayerCard({ id, status: true, audioUrl: file, listFile: fileList, nameBookFiles: nameBookFiles }));
            setTimeout(() => dispatch(currentPlayerStartOrPause(true)), 100);
        }, 100);
    }
    const handlePause = () => dispatch(currentPlayerStartOrPause(false));
    const openModalDown = () => {
        dispatch(setModalOpen('format'));
        dispatch(setModalIdCardstate(Number(id) || 0));
    };

    return (
        <div className="aycard">
            <CardPanel
                id={id}
                routersLink={routers.audioBook_I.link}
                handlePause={handlePause}
                handlePlay={handlePlay}
                openModalDown={openModalDown}
                playCard={playCard}
                favorite={favorite}
                img={img}
                handleFavorite={handleFavorite}
            />
            <div className="aycard__inf">
                <Link to={`${routers.audioBook_I.link}${id}`} className="aycard__inf_opas" title={title}></Link>
                {(title?.length > 0) && <Link to={`${routers.audioBook_I.link}${id}`} className="aycard__title font_w_500" title={title}>{title}</Link>}
                {(des?.length > 0) && <p className="aycard__des font_w_300">{des}</p>}
                <div className="aycard__inf-grid">
                    {
                        (!!performer?.length) && (
                            <div className="card-item-info">
                                <IconSprite id='icon_into_card_performer' />
                                <span>{performer}</span>
                            </div>
                        )
                    }
                    {
                        (!!year?.length) && (
                            <div className="card-item-info">
                                <IconSprite id='icon_into_card_pencil' />
                                <span>{year} рік</span>
                            </div>
                        )
                    }
                    {
                        (!!fileType?.length) && (
                            <div className="card-item-info">
                                <IconSprite id='icon_into_card_file' />
                                <span>{fileType}</span>
                            </div>
                        )
                    }
                    {
                        (!!fileSize?.length) && (
                            <div className="card-item-info">
                                <IconSprite id='icon_into_card_size' />
                                <span>{fileSize}</span>
                            </div>
                        )
                    }
                    {
                        (!!time) && (
                            <div className="card-item-info">
                                <IconSprite id='icon_into_card_time' />
                                <span>{timeCard(time)}</span>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}