import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type IconType = 'icon_notif_book' | 'icon_notif_layers' | 'icon_notif_twobooks' | 'icon_notif_section' | '';
export type Objitem = {
    id: number,
    message: string,
    icon?: IconType
}

export interface CounterState {
    list: Objitem[],
}

const initialState: CounterState = {
    list: [],
};

export const slice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        addNotification(state, action: PayloadAction<{ message: string, icon?: IconType }>) {
            let idLast = state.list.length + 1;
            state.list.unshift({ id: idLast, icon: '', ...action.payload });
        },
        closeNotification(state, action: PayloadAction<number>) {
            state.list = state.list.filter((el: Objitem) => (el.id != action.payload));
        }
    }
});

export const {
    addNotification,
    closeNotification
} = slice.actions;

export default slice.reducer;
