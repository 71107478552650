import { FC, useState } from "react";

import { FieldInputCodeBook } from "../fieldInputCodeBook";
import { Button } from "../button";

import { validFormCodeBook } from "../../units/valids";
import { useAppDispatch, useAppSelector } from "hooks/state";
import { addBooks } from "../../stores/controlers/books";

import { useFilter } from "../../hooks/filter";

export interface CodeBookProps {
    handleClose: Function,
};

export const CodeBook: FC<CodeBookProps> = ({
    handleClose
}) => {
    const dispatch = useAppDispatch();
    const { books: { loadBtnActivatioBook } } = useAppSelector(state => state);
    const [error, setError] = useState<{ status: boolean, message: string }>({ status: false, message: 'Помилка' });
    const [code, setCode] = useState<string>('');
    const { deleteParams } = useFilter();

    const send = () => {
        dispatch(addBooks({ key: code, setError, calback: deleteParams }));
    }
    
    return (
        <div className="m-f-t">
            <div className="modal-content modal-content-360 g-modal-20">
                <div className="modal__title text_center font_w_500">{error.status ? "Код невірний!" : "Введіть код придбаної Вами книги"}</div>
                <div className="modal-form">
                    {
                        error.status && <div className="form-er-in-t">Ввести код повторно</div>
                    }
                    <FieldInputCodeBook
                        placeholder="Код книги"
                        name="codeBook"
                        valueIn={code}
                        handleChange={(value: string) => setCode(value)}
                        errorMessage={error.status ? error.message : ""}
                    />
                </div>
                <div className="modal__two__btn">
                    <div>
                        <Button label="Готово" load={loadBtnActivatioBook} disabled={!validFormCodeBook(code)} handleClick={() => send()} />
                    </div>
                    <div>
                        <Button label="Пропустити" type='b' handleClick={() => handleClose()} />
                    </div>
                </div>
            </div>
        </div>
    )
}